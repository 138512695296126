import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'app/core/auth/account.service';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { ConfigurationService } from 'app/core/service/configuration.service';
import { ClassificationPlanService } from 'app/core/service/classification-plan.service';
import { PREVIOUS_URL_FOR_ERROR_PAGE } from 'app/shared/constants/session-storage.constants';
import { Authority } from 'app/shared/enum/authority.enum';
import { ClassificationPLanDocumentDTO } from 'app/shared/model/fact.model';

@Component({
  selector: 'jhi-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorKey?: string;
  errorNumber: number | null;
  documentId: number;
  public cockpitURL: string;
  public previousUrl: string | null;
  public readonly Authority = Authority;

  constructor(
    private accountService: AccountService,
    private stateStorageService: StateStorageService,
    private classificationPlanService: ClassificationPlanService,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(routeData => {
      if (routeData.errorMessage) {
        this.errorKey = routeData.errorMessage;

        const number = Number(this.errorKey?.replace('error.http.', ''));
        this.errorNumber = isNaN(number) ? null : number;
      }

      this.documentId = this.accountService.documentId;

      if (this.errorNumber === 403 && this.documentId) {
        this.redirectToClassificationPLan();
      }

    });

    this.configurationService.getConfiguration().subscribe(data => {
      this.cockpitURL = data.sppUri;
    });

    this.previousUrl = this.stateStorageService.getUrl(PREVIOUS_URL_FOR_ERROR_PAGE) ?? null;

  }

  public redirectToClassificationPLan(): void {
    this.classificationPlanService.getClassificationPlanDocuments(this.documentId).subscribe((classificationPLan: ClassificationPLanDocumentDTO[]) => {
      // doc.hasRight should never be true for the current document, we had a condition to avoid an infinite loop
      const document = classificationPLan.find((doc) => doc.hasRight && doc.id !== this.documentId);
      if (document) {
        window.location.href = `/document/${document.id}`;
      }
    });
  }

  public previous(): void {
    if (this.previousUrl) {
      this.stateStorageService.clearUrl(PREVIOUS_URL_FOR_ERROR_PAGE);
      this.router.navigateByUrl(this.previousUrl);
    }
  }

  public navigate(path: string): void {
    this.router.navigate([`/document/${this.documentId}/${path}`]);
  }
}
