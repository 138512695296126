import { Component, OnInit, RendererFactory2, Renderer2, OnDestroy, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { AccountService } from 'app/core/auth/account.service';
import { Observable, Subscription } from 'rxjs';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { NavbarService } from '../navbar/navbar.service';

import { PREVIOUS_URL_FOR_ERROR_PAGE } from 'app/shared/constants/session-storage.constants';

import configurationSVG from '!!raw-loader!../../../content/images/toolbar/ic-configuration.svg';
import findReplaceSVG from '!!raw-loader!../../../content/images/toolbar/ic-find-replace.svg';
import templateSVG from '!!raw-loader!../../../content/images/toolbar/ic-gabarit.svg';
import historySVG from '!!raw-loader!../../../content/images/toolbar/ic-historique.svg';
import importSVG from '!!raw-loader!../../../content/images/toolbar/ic-import.svg';
import correspondenceTableSVG from '!!raw-loader!../../../content/images/toolbar/ic-table-correspondance.svg';
import tagXbrlSVG from '!!raw-loader!../../../content/images/toolbar/ic-tag-xbrl.svg';
import validationSVG from '!!raw-loader!../../../content/images/toolbar/ic-validation.svg';
import viewerSVG from '!!raw-loader!../../../content/images/toolbar/ic-viewer.svg';

import documentSVG from '!!raw-loader!../../../content/images/data/document.svg';
import folderSVG from '!!raw-loader!../../../content/images/data/folder.svg';
import spreadsheetSVG from '!!raw-loader!../../../content/images/data/spreadsheet.svg';
import uploadSVG from '!!raw-loader!../../../content/images/data/upload.svg';
import calendarSVG from '!!raw-loader!../../../content/images/data/calendrier.svg';
import clockSVG from '!!raw-loader!../../../content/images/data/horloge.svg';
import xbrlTableSVG from '!!raw-loader!../../../content/images/data/xbrl-table.svg';
import xslxSVG from '!!raw-loader!../../../content/images/data/xslx-file.svg';
import excelSVG from '!!raw-loader!../../../content/images/data/icone_Excel.svg';
import padlockSVG from '!!raw-loader!../../../content/images/data/cadenas.svg';
import databaseSVG from '!!raw-loader!../../../content/images/data/icone_database.svg';
import xhtmlSVG from '!!raw-loader!../../../content/images/data/xhtml-file.svg';

import longArrowDownSvg from '!!raw-loader!../../../content/images/long-arrow-down.svg';
import arrowDownBigSVG from '!!raw-loader!../../../content/images/arrow-down-big.svg';
import chevronLeftSVG from '!!raw-loader!../../../content/images/chevron-left.svg';
import chevronRightSVG from '!!raw-loader!../../../content/images/chevron-right.svg';
import arrowLeftSVG from '!!raw-loader!../../../content/images/arrow-left.svg';
import arrowRightSVG from '!!raw-loader!../../../content/images/arrow-right.svg';
import arrowDownSVG from '!!raw-loader!../../../content/images/arrow-down.svg';
import arrowBackSVG from '!!raw-loader!../../../content/images/fleche-2-gauche.svg';
import arrowUpSVG from '!!raw-loader!../../../content/images/arrow-up.svg';
import flecheDownSVG from '!!raw-loader!../../../content/images/fleche-down.svg';
import flecheRightSVG from '!!raw-loader!../../../content/images/fleche-right.svg';
import bigCrossSVG from '!!raw-loader!../../../content/images/croix-grande.svg';
import whiteCrossSVG from '!!raw-loader!../../../content/images/croix.svg';
import valid30SVG from '!!raw-loader!../../../content/images/valide-30.svg';
import error30SVG from '!!raw-loader!../../../content/images/erreur-30.svg';
import warningSVG from '!!raw-loader!../../../content/images/warning.svg';
import refreshSVG from '!!raw-loader!../../../content/images/rafraichir.svg';
import imagesRefreshSVG from '!!raw-loader!../../../content/images/refresh-images.svg';
import previewRefreshSVG from '!!raw-loader!../../../content/images/preview-images.svg';
import replaceSVG from '!!raw-loader!../../../content/images/remplacement.svg';
import ovalSVG from '!!raw-loader!../../../content/images/oval.svg';
import searchSVG from '!!raw-loader!../../../content/images/search.svg';
import trashSVG from '!!raw-loader!../../../content/images/poubelle.svg';
import crayonSVG from '!!raw-loader!../../../content/images/crayon.svg';
import imageNotFoundSVG from '!!raw-loader!../../../content/images/image_not_found.svg';
import deleteOutlineSVG from '!!raw-loader!../../../content/images/delete_outline.svg';
import moveImageSVG from '!!raw-loader!../../../content/images/move_image.svg';
import addCircle from '!!raw-loader!../../../content/images/add_circle.svg';
import iconTrashSVG from '!!raw-loader!../../../content/images/icon-trash.svg';
import errorSVG from '!!raw-loader!../../../content/images/icon-error.svg';
import lookupSVG from '!!raw-loader!../../../content/images/lookup.svg';
import downloadSVG from '!!raw-loader!../../../content/images/download.svg';
import iconEditSVG from '!!raw-loader!../../../content/images/icon-edit.svg';
import iconOpenNewTabSVG from '!!raw-loader!../../../content/images/open-new-tab.svg';

import iconArrowUpSVG from '!!raw-loader!../../../content/images/icon-arrow-up.svg';
import iconArrowDownSVG from '!!raw-loader!../../../content/images/icon-arrow-down.svg';
import iconArrowUpNoBorder from '!!raw-loader!../../../content/images/icon-arrow-up-no-border.svg';
import iconArrowDownNoBorder from '!!raw-loader!../../../content/images/icon-arrow-down-no-border.svg';
import iconArrowLeftNoBorder from '!!raw-loader!../../../content/images/icon-arrow-left-no-border.svg';
import iconArrowRightNoBorder from '!!raw-loader!../../../content/images/icon-arrow-right-no-border.svg';

import iconDuplicateSVG from '!!raw-loader!../../../content/images/icon-duplicate.svg';

import tocSVG from '!!raw-loader!../../../content/images/toc/toc.svg';
import readOnlySVG from '!!raw-loader!../../../content/images/toc/visibility-24-px.svg';
import noRightsSVG from '!!raw-loader!../../../content/images/toc/visibility-off-24-px.svg';
import iconRoundPenSVG from '!!raw-loader!../../../content/images/toc/icon-rond-pen.svg';
import iconRoundLockSVG from '!!raw-loader!../../../content/images/toc/icon-rond-lock.svg';
import iconRoundAddSVG from '!!raw-loader!../../../content/images/toc/icon-add.svg';
import iconRoundCheckSVG from '!!raw-loader!../../../content/images/toc/icon-check.svg';
import iconRoundRefreshSVG from '!!raw-loader!../../../content/images/toc/icon-rond-synchro.svg';
import iconRoundTocConfigSVG from '!!raw-loader!../../../content/images/toc/icon-rond-toc-config.svg';

import iconLockSVG from '!!raw-loader!../../../content/images/toc/icon-lock.svg';
import iconUnlockSVG from '!!raw-loader!../../../content/images/toc/icon-unlock.svg';

import tagCircleSVG from '!!raw-loader!../../../content/images/tag/cercle.svg';
import tagLargeDotSVG from '!!raw-loader!../../../content/images/tag/point-grand.svg';
import tagSmallDotSVG from '!!raw-loader!../../../content/images/tag/point-petit.svg';
import tagIndigoSVG from '!!raw-loader!../../../content/images/tag/tag.svg';
import tagSearchSVG from '!!raw-loader!../../../content/images/tag/loupe.svg';
import tagDataEditorSVG from '!!raw-loader!../../../content/images/tag/tag-data-editor.svg';
import tagAddCircleSVG from '!!raw-loader!../../../content/images/tag/icon-add-circle.svg';
import tagRemoveCircleSVG from '!!raw-loader!../../../content/images/tag/icon-remove-circle.svg';

import iconeExportSVG from '!!raw-loader!../../../content/images/editor/icon-export.svg';
import exportPdfSVG from '!!raw-loader!../../../content/images/export/PDF.svg';
import exportSiteSVG from '!!raw-loader!../../../content/images/export/Site.svg';
import exportWordSVG from '!!raw-loader!../../../content/images/export/Word.svg';
import exportXhtmlSVG from '!!raw-loader!../../../content/images/export/xHTML.svg';
import exportEsefSVG from '!!raw-loader!../../../content/images/export/ESEF.svg';
import exportXliffSVG from '!!raw-loader!../../../content/images/export/XLIFF.svg';
import exportLoreSVG from '!!raw-loader!../../../content/images/export/LORE.svg';

import exportCleanedHtmlSVG from '!!raw-loader!../../../content/images/export/cleaned_html.svg';
import exportIxbrlViewerSVG from '!!raw-loader!../../../content/images/export/ixbrl_viewer.svg';
import iconExport from '!!raw-loader!../../../content/images/export/icon-export.svg';

import icon1colSVG from '!!raw-loader!../../../content/images/style/colonne-1.svg';
import icon2colSVG from '!!raw-loader!../../../content/images/style/colonne-2.svg';
import icon3colSVG from '!!raw-loader!../../../content/images/style/colonne-3.svg';

import style1colSVG from '!!raw-loader!../../../content/images/style/1col.svg';
import style2colSVG from '!!raw-loader!../../../content/images/style/2col.svg';
import style3colSVG from '!!raw-loader!../../../content/images/style/3col.svg';

import alignLeftSVG from '!!raw-loader!../../../content/images/style/align-left.svg';
import alignCenterSVG from '!!raw-loader!../../../content/images/style/align-center.svg';
import alignRightSVG from '!!raw-loader!../../../content/images/style/align-right.svg';
import alignJustifySVG from '!!raw-loader!../../../content/images/style/align-justify.svg';

import verticalAlignTopSVG from '!!raw-loader!../../../content/images/style/vertical-align-top.svg';
import verticalAlignMiddleSVG from '!!raw-loader!../../../content/images/style/vertical-align-center.svg';
import verticalAlignBottomSVG from '!!raw-loader!../../../content/images/style/vertical-align-bottom.svg';

import objectLeftSVG from '!!raw-loader!../../../content/images/style/object-left.svg';
import objectFullSVG from '!!raw-loader!../../../content/images/style/object-full-width.svg';
import objectRightSVG from '!!raw-loader!../../../content/images/style/object-right.svg';

import compliantLeftSVG from '!!raw-loader!../../../content/images/style/compliant-left.svg';
import compliantRightSVG from '!!raw-loader!../../../content/images/style/compliant-right.svg';
import favoriteLeftSVG from '!!raw-loader!../../../content/images/style/favorite-left.svg';
import favoriteRightSVG from '!!raw-loader!../../../content/images/style/favorite-right.svg';
import noncompliantLeftSVG from '!!raw-loader!../../../content/images/style/noncompliant-left.svg';
import noncompliantRightSVG from '!!raw-loader!../../../content/images/style/noncompliant-right.svg';
import resetSVG from '!!raw-loader!../../../content/images/style/icon-del.svg';
import selectTableSVG from '!!raw-loader!../../../content/images/style/select-table.svg';
import selectedTableSVG from '!!raw-loader!../../../content/images/style/selected-table.svg';

import list_olSVG from '!!raw-loader!../../../content/images/style/menu/alphaNumeric.svg';
import list_ulSVG from '!!raw-loader!../../../content/images/style/menu/bulletList.svg';
import colorsSVG from '!!raw-loader!../../../content/images/style/menu/colors.svg';
import colorsMatrixSVG from '!!raw-loader!../../../content/images/style/menu/colors-matrix.svg';
import fontFamiliesSVG from '!!raw-loader!../../../content/images/style/menu/fontFamilies.svg';
import fontsSVG from '!!raw-loader!../../../content/images/style/menu/fonts.svg';
import gabaritSVG from '!!raw-loader!../../../content/images/style/menu/gabarit.svg';
import graphicsSVG from '!!raw-loader!../../../content/images/style/menu/graphics.svg';
import xhtmlStyleSVG from '!!raw-loader!../../../content/images/style/menu/html-UD.svg';
import importCssSVG from '!!raw-loader!../../../content/images/style/menu/importCSS.svg';
import paragraphSVG from '!!raw-loader!../../../content/images/style/menu/paragraph.svg';
import summarySVG from '!!raw-loader!../../../content/images/style/menu/summary.svg';
import titleSVG from '!!raw-loader!../../../content/images/style/menu/title.svg';
import footnoteSVG from '!!raw-loader!../../../content/images/style/menu/footnote.svg';
import tableModelerSVG from '!!raw-loader!../../../content/images/style/menu/table-modeler.svg';
import borderSVG from '!!raw-loader!../../../content/images/style/menu/border.svg';
import pageTemplatesSVG from '!!raw-loader!../../../content/images/style/menu/page-templates.svg';
import pageTemplatesPagesSVG from '!!raw-loader!../../../content/images/style/menu/page-templates-pages.svg';
import pageTemplatesBackgroundsSVG from '!!raw-loader!../../../content/images/style/menu/page-templates-backgrounds.svg';
import pageModelsSVG from '!!raw-loader!../../../content/images/style/menu/icon-modele.svg';
import projectCssSVG from '!!raw-loader!../../../content/images/admin-editor/project_css.svg';

import linkSVG from '!!raw-loader!../../../content/images/style/link.svg';
import link2SVG from '!!raw-loader!../../../content/images/style/link-2.svg';
import cancelSVG from '!!raw-loader!../../../content/images/cancel.svg';
import checkSVG from '!!raw-loader!../../../content/images/check.svg';

import menuCloseSVG from '!!raw-loader!../../../content/images/menu/close.svg';
import eolItemSVG from '!!raw-loader!../../../content/images/menu/eol-item.svg';
import cockpitSVG from '!!raw-loader!../../../content/images/menu/cockpit.svg';

import previewSVG from '!!raw-loader!../../../content/images/preview/preview.svg';
import previewImageSVG from '!!raw-loader!../../../content/images/preview/preview-image.svg';

import alignCenterMiddleSVG from '!!raw-loader!../../../content/images/style/icon-align-1.svg';
import alignLeftMiddleSVG from '!!raw-loader!../../../content/images/style/icon-align-2.svg';
import alignRightTopSVG from '!!raw-loader!../../../content/images/style/icon-align-3.svg';
import alignBottomMiddleSVG from '!!raw-loader!../../../content/images/style/icon-align-4.svg';
import alignMiddleMiddleSVG from '!!raw-loader!../../../content/images/style/icon-align-5.svg';
import alignTopMiddleSVG from '!!raw-loader!../../../content/images/style/icon-align-6.svg';

import saveSVG from '!!raw-loader!../../../content/images/save.svg';
import dmSVG from '!!raw-loader!../../../content/images/dm/download-manager.svg';
import dmDoneSVG from '!!raw-loader!../../../content/images/dm/download-manager-done.svg';
import dmErrorSVG from '!!raw-loader!../../../content/images/dm/download-manager-error.svg';
import dmInProgressSVG from '!!raw-loader!../../../content/images/dm/download-manager-in-progress.svg';
import dmWarningSVG from '!!raw-loader!../../../content/images/dm/download-manager-warning.svg';

import xliffTradSVG from '!!raw-loader!../../../content/images/admin-editor/trad_eol_white.svg';
import tableLabelsSVG from '!!raw-loader!../../../content/images/admin-editor/table_labels.svg';
import nomenclatureStylesSVG from '!!raw-loader!../../../content/images/admin-editor/nomenclature_styles.svg';
import adminPageModelsSVG from '!!raw-loader!../../../content/images/admin-editor/page_models.svg';
import projectConfigurationSVG from '!!raw-loader!../../../content/images/admin-editor/project_configuration.svg';
import htmlUDSVG from '!!raw-loader!../../../content/images/admin-editor/html-UD.svg';
import userListSVG from '!!raw-loader!../../../content/images/admin-editor/user-list.svg';
import separatorSVG from '!!raw-loader!../../../content/images/admin-editor/separator.svg';
import exportMonitoringSVG from '!!raw-loader!../../../content/images/admin-editor/icon-export-monitoring.svg';
import macroTagsSVG from '!!raw-loader!../../../content/images/admin-editor/macros-tag.svg';
import projectImportExportSVG from '!!raw-loader!../../../content/images/admin-editor/project-import-export.svg';

// Zoom
import minusSVG from '!!raw-loader!../../../content/images/zoom/minus.svg';
import plusSVG from '!!raw-loader!../../../content/images/zoom/plus.svg';
import zoomInSVG from '!!raw-loader!../../../content/images/zoom/icon-zoomIn.svg';
import zoomOutSVG from '!!raw-loader!../../../content/images/zoom/icon-zoomOut.svg';
import zoomSVG from '!!raw-loader!../../../content/images/zoom/icon-zoom.svg';

// Highchart
import defaultHighchart from '!!raw-loader!../../../content/images/style/highchart/default.svg';
import areaHighchart from '!!raw-loader!../../../content/images/style/highchart/area.svg';
import barHighchart from '!!raw-loader!../../../content/images/style/highchart/bar.svg';
import linesHighchart from '!!raw-loader!../../../content/images/style/highchart/lines.svg';
import pieHighchart from '!!raw-loader!../../../content/images/style/highchart/pie.svg';
import polarHighchart from '!!raw-loader!../../../content/images/style/highchart/polar.svg';
import stockHighchart from '!!raw-loader!../../../content/images/style/highchart/stock.svg';
import columnHighchart from '!!raw-loader!../../../content/images/style/highchart/column.svg';
import scatterHighchart from '!!raw-loader!../../../content/images/style/highchart/scatter.svg';

import iconMenuDots from '!!raw-loader!../../../content/images/icon-menu-dots.svg';
import { DownloadManagerService } from 'app/core/service/download-manager.service';
import { MatSidenav } from '@angular/material/sidenav';

import { faviconManagement } from 'app/shared/enum/favicon-management.enum';

import splitTable from '!!raw-loader!../../../content/images/split-table.svg';

@Component({
  selector: 'jhi-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  public isNavbarOpened$: Observable<boolean>;
  private renderer: Renderer2;
  private currentUrl: string = '';
  private previousUrl: string = '';
  private iconx32: HTMLLinkElement | null = document.querySelector(faviconManagement.ICON_32);
  private iconx16: HTMLLinkElement | null = document.querySelector(faviconManagement.ICON_16);
  private appleTouchIcon: HTMLLinkElement | null = document.querySelector(faviconManagement.ICON_APPLE);
  private defaultIcon: HTMLLinkElement | null = document.querySelector(faviconManagement.ICON_DEFAULT);

  private _stateSubscription: Subscription;
  @ViewChild('dmnav', { static: false }) dmnav: MatSidenav;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private titleService: Title,
    private accountService: AccountService,
    private translateService: TranslateService,
    private navbarService: NavbarService,
    private rootRenderer: RendererFactory2,
    private route: ActivatedRoute,
    private stateStorageService: StateStorageService,
    private downloadManagerService: DownloadManagerService
  ) {
    this.isNavbarOpened$ = this.navbarService.isNavbarOpened();
    this.addSvgIcons();
    this.renderer = this.rootRenderer.createRenderer(document.querySelector('html'), null);
  }

  public ngOnInit(): void {
    this.accountService.documentId = +this.route.snapshot.params.documentId;
    // try to log in automatically
    this.accountService.identity().subscribe();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updatePageTheme();
        this.updateFavicon();
        this.updateTitle();
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.stateStorageService.storeUrl(this.previousUrl, PREVIOUS_URL_FOR_ERROR_PAGE);
      }

      if (event instanceof NavigationError) {
        this.router.navigate(['/404']);
      }
    });

    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.updateTitle();
      this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);
    });

    // Toggle download manager panel
    this._stateSubscription = this.downloadManagerService.getPanelState().subscribe(open => {
      if (open) {
        this.dmnav.open();
      } else {
        this.dmnav.close();
      }
    });
  }

  ngOnDestroy(): void {
    this._stateSubscription.unsubscribe();
  }

  public collapseNavbar(): void {
    this.navbarService.collapseNavbar();
  }

  private getPage(routeSnapshot: ActivatedRouteSnapshot): string {
    let page: string = routeSnapshot.data?.['page'] ? routeSnapshot.data['page'] : '';
    if (routeSnapshot.firstChild) {
      page = this.getPage(routeSnapshot.firstChild) || page;
    }
    return page;
  }

  private updatePageTheme(): void {
    let page = this.getPage(this.router.routerState.snapshot.root);
    if (!page) {
      page = 'html-editor';
    }
    document.body.className = page;
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data?.['pageTitle'] ? routeSnapshot.data['pageTitle'] : '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private updateTitle(): void {
    let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
    if (!pageTitle) {
      pageTitle = 'global.title';
    }
    this.translateService.get(pageTitle).subscribe(title => this.titleService.setTitle(title));
  }

  // Get the value of the type of icons by page (set in the routers)
  private getFavIcon(routeSnapshot: ActivatedRouteSnapshot): string {
    let favIcon: string = routeSnapshot.data?.['favIcon'] ? routeSnapshot.data['favIcon'] : '';
    if (routeSnapshot.firstChild) {
      favIcon = this.getFavIcon(routeSnapshot.firstChild) || favIcon;
    }
    return favIcon;
  }

  // Update the favicons links in the index.html
  private updateFavicon(): void {
    let favIcon = this.getFavIcon(this.router.routerState.snapshot.root);
    if (!favIcon) {
      favIcon = 'global.favIcon';
    }

    const path = faviconManagement.PATH;

    // Translate the value of the type of icon into the name of the needed value
    // And set the linked favicons with the new link to the needed favicon
    this.translateService.get(favIcon).subscribe(pageName => {
      if (this.iconx32) {
        this.iconx32.href = path + faviconManagement.X32 + pageName + faviconManagement.IMAGE_EXTENTION;
      }
      if (this.iconx16) {
        this.iconx16.href = path + faviconManagement.X16 + pageName + faviconManagement.IMAGE_EXTENTION;
      }
      if (this.appleTouchIcon) {
        this.appleTouchIcon.href = path + faviconManagement.APPLETOUCH + pageName + faviconManagement.IMAGE_EXTENTION;
      }
      if (this.defaultIcon) {
        this.defaultIcon.href = path + faviconManagement.DEFAULT_FAVICON + pageName + faviconManagement.ICON_EXTENTION;
      }
    });
  }

  private addSvgIcon(label: string, svg: string): void {
    this.iconRegistry.addSvgIconLiteral(label, this.sanitizer.bypassSecurityTrustHtml(svg));
  }

  private addSvgIcons(): void {
    this.addSvgIcon('configuration', configurationSVG);
    this.addSvgIcon('find-replace', findReplaceSVG);
    this.addSvgIcon('template', templateSVG);
    this.addSvgIcon('history', historySVG);
    this.addSvgIcon('import', importSVG);
    this.addSvgIcon('correspondence-table', correspondenceTableSVG);
    this.addSvgIcon('tag-xbrl', tagXbrlSVG);
    this.addSvgIcon('validation', validationSVG);
    this.addSvgIcon('viewer', viewerSVG);

    this.addSvgIcon('document', documentSVG);
    this.addSvgIcon('folder', folderSVG);
    this.addSvgIcon('spreadsheet', spreadsheetSVG);
    this.addSvgIcon('upload', uploadSVG);
    this.addSvgIcon('calendar', calendarSVG);
    this.addSvgIcon('clock', clockSVG);
    this.addSvgIcon('xbrlTable', xbrlTableSVG);
    this.addSvgIcon('xslx', xslxSVG);
    this.addSvgIcon('excel', excelSVG);
    this.addSvgIcon('padlock', padlockSVG);
    this.addSvgIcon('database', databaseSVG);
    this.addSvgIcon('xhtml', xhtmlSVG);

    this.addSvgIcon('longArrowDown', longArrowDownSvg);
    this.addSvgIcon('bigArrowDown', arrowDownBigSVG);

    this.addSvgIcon('chevron-left', chevronLeftSVG);
    this.addSvgIcon('chevron-right', chevronRightSVG);
    this.addSvgIcon('arrow-left', arrowLeftSVG);
    this.addSvgIcon('arrow-right', arrowRightSVG);
    this.addSvgIcon('arrow-down', arrowDownSVG);
    this.addSvgIcon('arrow-back', arrowBackSVG);
    this.addSvgIcon('arrow-up', arrowUpSVG);
    this.addSvgIcon('fleche-down', flecheDownSVG);
    this.addSvgIcon('fleche-right', flecheRightSVG);
    this.addSvgIcon('big-cross', bigCrossSVG);
    this.addSvgIcon('white-cross', whiteCrossSVG);
    this.addSvgIcon('valid-30', valid30SVG);
    this.addSvgIcon('error-30', error30SVG);
    this.addSvgIcon('warning', warningSVG);
    this.addSvgIcon('refresh', refreshSVG);
    this.addSvgIcon('images-refresh', imagesRefreshSVG);
    this.addSvgIcon('preview-refresh', previewRefreshSVG);
    this.addSvgIcon('replace', replaceSVG);
    this.addSvgIcon('oval', ovalSVG);
    this.addSvgIcon('search', searchSVG);
    this.addSvgIcon('trash', trashSVG);
    this.addSvgIcon('crayon', crayonSVG);
    this.addSvgIcon('image-not-found', imageNotFoundSVG);
    this.addSvgIcon('delete-outline', deleteOutlineSVG);
    this.addSvgIcon('move-image', moveImageSVG);
    this.addSvgIcon('add-circle', addCircle);
    this.addSvgIcon('icon-trash', iconTrashSVG);
    this.addSvgIcon('icon-edit', iconEditSVG);
    this.addSvgIcon('icon-open-new-tab', iconOpenNewTabSVG);

    this.addSvgIcon('error', errorSVG);
    this.addSvgIcon('icon-arrow-up', iconArrowUpSVG);
    this.addSvgIcon('icon-arrow-down', iconArrowDownSVG);
    this.addSvgIcon('icon-arrow-up-no-border', iconArrowUpNoBorder);
    this.addSvgIcon('icon-arrow-down-no-border', iconArrowDownNoBorder);
    this.addSvgIcon('icon-arrow-right-no-border', iconArrowRightNoBorder);
    this.addSvgIcon('icon-arrow-left-no-border', iconArrowLeftNoBorder);
    this.addSvgIcon('toc', tocSVG);
    this.addSvgIcon('lookup', lookupSVG);
    this.addSvgIcon('read-only', readOnlySVG);
    this.addSvgIcon('no-rights', noRightsSVG);
    this.addSvgIcon('icon-duplicate', iconDuplicateSVG);
    this.addSvgIcon('round-pen', iconRoundPenSVG);
    this.addSvgIcon('round-lock', iconRoundLockSVG);
    this.addSvgIcon('round-add', iconRoundAddSVG);
    this.addSvgIcon('round-check', iconRoundCheckSVG);
    this.addSvgIcon('round-refresh', iconRoundRefreshSVG);
    this.addSvgIcon('round-toc-config', iconRoundTocConfigSVG);

    this.addSvgIcon('locked', iconLockSVG);
    this.addSvgIcon('unlocked', iconUnlockSVG);
    this.addSvgIcon('download', downloadSVG);

    this.addSvgIcon('tag-circle', tagCircleSVG);

    this.addSvgIcon('tag-large-dot', tagLargeDotSVG);
    this.addSvgIcon('tag-small-dot', tagSmallDotSVG);
    this.addSvgIcon('tag-indigo', tagIndigoSVG);
    this.addSvgIcon('tag-search', tagSearchSVG);
    this.addSvgIcon('tag-data-editor', tagDataEditorSVG);
    this.addSvgIcon('tag-add-circle', tagAddCircleSVG);
    this.addSvgIcon('tag-remove-circle', tagRemoveCircleSVG);

    this.addSvgIcon('icon-export', iconeExportSVG);
    this.addSvgIcon('export-pdf', exportPdfSVG);
    this.addSvgIcon('export-site', exportSiteSVG);
    this.addSvgIcon('export-word', exportWordSVG);
    this.addSvgIcon('export-xhtml', exportXhtmlSVG);
    this.addSvgIcon('export-esef', exportEsefSVG);
    this.addSvgIcon('export-xliff', exportXliffSVG);
    this.addSvgIcon('export-cleaned-html', exportCleanedHtmlSVG);
    this.addSvgIcon('export-lore', exportLoreSVG);
    this.addSvgIcon('export-ixbrl-viewer', exportIxbrlViewerSVG);
    this.addSvgIcon('icon-export-2', iconExport);

    this.addSvgIcon('icon-1col', icon1colSVG);
    this.addSvgIcon('icon-2col', icon2colSVG);
    this.addSvgIcon('icon-3col', icon3colSVG);

    this.addSvgIcon('style-1col', style1colSVG);
    this.addSvgIcon('style-2col', style2colSVG);
    this.addSvgIcon('style-3col', style3colSVG);

    this.addSvgIcon('align-left', alignLeftSVG);
    this.addSvgIcon('align-center', alignCenterSVG);
    this.addSvgIcon('align-right', alignRightSVG);
    this.addSvgIcon('align-justify', alignJustifySVG);

    this.addSvgIcon('object-left', objectLeftSVG);
    this.addSvgIcon('object-full-width', objectFullSVG);
    this.addSvgIcon('object-right', objectRightSVG);

    this.addSvgIcon('vertical-align-top', verticalAlignTopSVG);
    this.addSvgIcon('vertical-align-middle', verticalAlignMiddleSVG);
    this.addSvgIcon('vertical-align-bottom', verticalAlignBottomSVG);

    this.addSvgIcon('compliant-left', compliantLeftSVG);
    this.addSvgIcon('compliant-right', compliantRightSVG);
    this.addSvgIcon('favorite-left', favoriteLeftSVG);
    this.addSvgIcon('favorite-right', favoriteRightSVG);
    this.addSvgIcon('noncompliant-left', noncompliantLeftSVG);
    this.addSvgIcon('noncompliant-right', noncompliantRightSVG);
    this.addSvgIcon('reset', resetSVG);
    this.addSvgIcon('select-table', selectTableSVG);
    this.addSvgIcon('selected-table', selectedTableSVG);

    // Menu style
    this.addSvgIcon('list_ol', list_olSVG);
    this.addSvgIcon('list_ul', list_ulSVG);
    this.addSvgIcon('colors', colorsSVG);
    this.addSvgIcon('colors-matrix', colorsMatrixSVG);
    this.addSvgIcon('font-families', fontFamiliesSVG);
    this.addSvgIcon('fonts', fontsSVG);
    this.addSvgIcon('gabarit', gabaritSVG);
    this.addSvgIcon('graphics', graphicsSVG);
    this.addSvgIcon('xhtml-style', xhtmlStyleSVG);
    this.addSvgIcon('import-css', importCssSVG);
    this.addSvgIcon('paragraph', paragraphSVG);
    this.addSvgIcon('summary', summarySVG);
    this.addSvgIcon('title', titleSVG);
    this.addSvgIcon('footnote', footnoteSVG);
    this.addSvgIcon('table-modeler', tableModelerSVG);
    this.addSvgIcon('border', borderSVG);
    this.addSvgIcon('page-templates', pageTemplatesSVG);
    this.addSvgIcon('page-templates-pages', pageTemplatesPagesSVG);
    this.addSvgIcon('page-templates-backgrounds', pageTemplatesBackgroundsSVG);
    this.addSvgIcon('page-section', pageModelsSVG);

    this.addSvgIcon('link', linkSVG);
    this.addSvgIcon('link2', link2SVG);
    this.addSvgIcon('cancel', cancelSVG);
    this.addSvgIcon('check', checkSVG);

    // Style Highchart
    this.addSvgIcon('default-highchart', defaultHighchart);
    this.addSvgIcon('area-highchart', areaHighchart);
    this.addSvgIcon('bar-highchart', barHighchart);
    this.addSvgIcon('line-highchart', linesHighchart);
    this.addSvgIcon('pie-highchart', pieHighchart);
    this.addSvgIcon('polar-highchart', polarHighchart);
    this.addSvgIcon('column-highchart', columnHighchart);
    this.addSvgIcon('stock-highchart', stockHighchart);
    this.addSvgIcon('scatter_and_bubble-highchart', scatterHighchart);
    this.addSvgIcon('more-highchart', defaultHighchart);

    // Menu
    this.addSvgIcon('menu-close', menuCloseSVG);
    this.addSvgIcon('eol-item', eolItemSVG);
    this.addSvgIcon('cockpit', cockpitSVG);

    this.addSvgIcon('align-center-middle', alignCenterMiddleSVG);
    this.addSvgIcon('align-left-middle', alignLeftMiddleSVG);
    this.addSvgIcon('align-right-top', alignRightTopSVG);
    this.addSvgIcon('align-bottom-middle', alignBottomMiddleSVG);
    this.addSvgIcon('align-middle-middle', alignMiddleMiddleSVG);
    this.addSvgIcon('align-top-middle', alignTopMiddleSVG);

    // Preview
    this.addSvgIcon('preview-classic', previewSVG);
    this.addSvgIcon('preview-image', previewImageSVG);

    // saveManager
    this.addSvgIcon('save', saveSVG);

    // DownloadManager
    this.addSvgIcon('dm-', dmSVG);
    this.addSvgIcon('dm-done', dmDoneSVG);
    this.addSvgIcon('dm-error', dmErrorSVG);
    this.addSvgIcon('dm-in_progress', dmInProgressSVG);
    this.addSvgIcon('dm-warning', dmWarningSVG);
    this.addSvgIcon('icon-menu-dots', iconMenuDots);

    // Zoom
    this.addSvgIcon('plus', plusSVG);
    this.addSvgIcon('minus', minusSVG);
    this.addSvgIcon('zoomIn', zoomInSVG);
    this.addSvgIcon('zoomOut', zoomOutSVG);
    this.addSvgIcon('zoom', zoomSVG);

    // Admin
    this.addSvgIcon('xliff', xliffTradSVG);
    this.addSvgIcon('table-labels', tableLabelsSVG);
    this.addSvgIcon('page-models', adminPageModelsSVG);
    this.addSvgIcon('nomenclature-styles', nomenclatureStylesSVG);
    this.addSvgIcon('project-configuration', projectConfigurationSVG);
    this.addSvgIcon('html-ud', htmlUDSVG);
    this.addSvgIcon('project-css', projectCssSVG);
    this.addSvgIcon('user-list', userListSVG);
    this.addSvgIcon('separator', separatorSVG);
    this.addSvgIcon('export-monitoring', exportMonitoringSVG);
    this.addSvgIcon('macro-tags', macroTagsSVG);
    this.addSvgIcon('project-import-export', projectImportExportSVG);

    // split table
    this.addSvgIcon('split-table', splitTable);
  }
}
