import { DataManagementType } from '../enum/data-management-type.enum';
import { ScaleType } from '../enum/scale-type.enum';
import { IDate } from './date.model';
import { IDocument } from './document.model';
import { ExportStatus } from './download-manager.model';

export interface IProject {
  project: Project;
  dates: IDate[];
}

export enum Regulator {
  ESMA = 'ESMA',
  SEC = 'SEC',
  NONE = 'N/A',
}

export enum ProjectType {
  ESEF = 'ESEF',
  I_10K = '10K',
  PROXY = 'Proxy',
  ECD = 'ECD',
  NONE = 'N/A',
}

export interface Project {
  defaultLanguage: string;
  defaultMonetaryUnit: string;
  dataManagement: DataManagementType;
  monetaryDecimals: number;
  moreLanguages: string[];
  documents: IDocument[];
  moreMonetaryUnits: string[];
  name: string;
  nonMonetaryDecimals: number;
  regulatorValue: Regulator;
  projectTypeValue: ProjectType;
  schema: string;
  scale: number;
  legalIdentifiers: string;
  publicationScale: number;
  groupingSeparator: string;
  decimalsSeparator: string;
  defaultNumericFormatId: number;
  defaultDateFormatId: number;
  projectLanguages: string[];
  scaleType: ScaleType;
  disableContributorDataAccess: boolean;
  generateTextBlockHierarchy: boolean;
  optionalTextBlockHierarchy: boolean;
  xbrlVersion: string;
}

export interface IProjectExport {
  id: number;
  exportStatus: ExportStatus;
  projectId: number;
  assetId: number;
  filename: string;
  exportTime: Date;
  userId: string;
  note: string;
  eolngVersion: string;
}

export interface IProjectCheckResponse {
  languages: { language: string; importable: boolean }[];
  valid: boolean;
  errors: string[];
  warnings: string[];
  activityIndicators: any;
}

export enum IProjectProgressStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  VALID = 'OK',
  KO = 'KO',
  DONE = 'DONE',
}

export interface IProjectProgress {
  id: string;
  status: IProjectProgressStatus;
  entities?: string;
  stepResult: string;
  localDateTime: string;
}
