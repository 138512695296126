import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextService } from './context.service';
import { SERVER_API_URL } from 'app/app.constants';

import { ClassificationPLanDocumentDTO } from 'app/shared/model/fact.model';;

@Injectable({ providedIn: 'root' })
export class ClassificationPlanService {
  public resourceUrl = `${SERVER_API_URL}api/documents`;

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
  ) {}

  public getClassificationPlanDocuments(documentIdParam:number = 0 ): Observable<ClassificationPLanDocumentDTO[]> {
    const documentId = documentIdParam > 0 ? documentIdParam : this.contextService.currentDocumentContext.id;
    return this.http.get<ClassificationPLanDocumentDTO[]>(
      `${SERVER_API_URL}/api/documents/${documentId}/classification-plan`
    );
  }


}
