import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SERVER_API_URL } from 'app/app.constants';
import pubsub from 'app/pubsub';
import {
  AREVIO_ASKFACT,
  AREVIO_ASKRCSFDATA,
  AREVIO_GOTFACT,
  AREVIO_GOTRCSFDATA,
  COPY_TEXT_BLOCK,
  PASTED_TEXT_BLOCK,
} from 'app/pubsub.topics';
import { AssetFileType } from 'app/shared/enum/asset-file-type.enum';
import { BalanceType } from 'app/shared/enum/balance-type.enum';
import { ParamImportDataEnum } from 'app/shared/enum/param-import-data.enum';
import { XbrlNumericTypes } from 'app/shared/enum/xbrl-numeric-types.enum';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';

import { XBRLUnit } from 'app/shared/enum/xbrl-units.enum';
import { IAssetResponse } from 'app/shared/model/asset.model';
import { IConcept, IEntryPointTable } from 'app/shared/model/concept.model';
import { IDate } from 'app/shared/model/date.model';
import { IAxis } from 'app/shared/model/dimension.model';
import {
  IDeleteFactResponse,
  IFact,
  IFactDTO,
  IFactReferenceToCheck,
  IFactsByRole,
  IFactValueUpdate,
  IUpdateFact,
  IUpdateFactResponse,
} from 'app/shared/model/fact.model';
import { IMappingLine } from 'app/shared/model/mapping-line.model';
import { AutomaticMapping } from 'app/shared/model/mapping.model';
import {
  CheckRcsfImportResult,
  ICellSelectionDTO,
  IFilesAndSheetsNames,
  IRCSFDataUpdated,
  RCSFCell,
  RCSFFile,
  RCSFImportData,
  RCSFSheet,
} from 'app/shared/model/rcsf.model';
import { ISheetList } from 'app/shared/model/sheet-list.model';
import { CheckTaxonomyResult, Taxonomy, ValidateReportResultDTO } from 'app/shared/model/taxonomy.model';
import { CheckXbrlResult } from 'app/shared/model/xbrl.model';
import { FileUtils } from 'app/shared/util/file-utils';
import { SpreadsheetUtils } from 'app/shared/util/spreadsheet-utils';

import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { ContextService } from './context.service';
import { checkDimensionsFilled } from 'app/shared/util/xbrl-utils';

@Injectable({ providedIn: 'root' })
export class ArevioService {
  private readonly CACHE_DURATION = 30 * 60000; // 30 min

  public taxonomyUrl: string;
  public factListUrl: string;
  public factUrl: string;
  public rcsfDataListUrl: string;
  public xbrlUrl: string;
  public mappingUrl: string;
  public checkCorrespondenceTableUrl: string;
  public exportCorrespondenceTableUrl: string;
  public rcsfUrl: string;
  public multiIndexUrl: string;

  private mappedFacts$: Map<number, Observable<IFact[]>> = new Map<number, Observable<IFact[]>>();
  private mappedFactsByRoles$: Map<number, Observable<IFactsByRole>> = new Map<number, Observable<IFactsByRole>>();
  private mappedTablesFromEntryPoints$: Map<string, Observable<IEntryPointTable[]>> = new Map<string, Observable<IEntryPointTable[]>>();
  private mappedFilteredConcepts$: Map<string, Observable<IEntryPointTable[]>> = new Map<string, Observable<IEntryPointTable[]>>();
  private mappedConcepts$: Map<number, Observable<IConcept[]>> = new Map<number, Observable<IConcept[]>>();
  private mappedRcsfSheets$: Map<string, Observable<RCSFFile>> = new Map<string, Observable<RCSFFile>>();
  private mappedTextBlock$: Map<number, Observable<IFact[]>> = new Map<number, Observable<IFact[]>>();
  private mappedTextBlockTableConcepts$: Map<number, Observable<IFactsByRole>> = new Map<number, Observable<IFactsByRole>>();

  constructor(private httpClient: HttpClient, private contextService: ContextService) {
    this.taxonomyUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/taxonomy`;
    this.factListUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/dynamic-data/facts`;
    this.factUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/dynamic-data/fact`;
    this.xbrlUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/dynamic-data/xbrl`;
    this.mappingUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/dynamic-data/mappings`;
    this.checkCorrespondenceTableUrl = `${this.mappingUrl}/check-rules`;
    this.exportCorrespondenceTableUrl = `${this.mappingUrl}/export`;
    this.rcsfUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/rcsf/data`;
    this.multiIndexUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/is-multi-indexed`;

    // here we are on a singleton service, .off is not necessary
    pubsub.on(AREVIO_ASKFACT, ({ detail }: CustomEvent) => {
      this.getFactById(detail.factXbrlId).subscribe((fact: IFact) => {
        pubsub.fire(`${AREVIO_GOTFACT}/${detail.factXbrlId}`, { fact }, detail.editorId);
      });
    });
    pubsub.on(AREVIO_ASKRCSFDATA, ({ detail }: CustomEvent) => {
      this.getRcsfSheetData(detail.filename, detail.sheetName, false).subscribe((file: RCSFFile) => {
        if (file.sheets.length > 0) {
          const data = file.sheets[0].rows.find(row => row.rowIdx === +detail.row)?.cols.find(cell => cell.colName === detail.colName);
          pubsub.fire(
            `${AREVIO_GOTRCSFDATA}/${detail.filename}/${detail.sheetName}/${detail.row}/${detail.colName}`,
            { data },
            detail.editorId
          );
        } else {
          pubsub.fire(
            `${AREVIO_GOTRCSFDATA}/${detail.filename}/${detail.sheetName}/${detail.row}/${detail.colName}`,
            { data: false },
            detail.editorId
          );
        }
      });
    });

    pubsub.on(COPY_TEXT_BLOCK, ({ detail }: CustomEvent) => {
      pubsub.fire(PASTED_TEXT_BLOCK, { textBlock: detail.textBlock, newId: this.updateFactXbrlId(detail.factId) }, detail.editorId);
    });
  }

  //
  // TAXONOMY
  //
  public importTaxonomy(taxonomyFile: File, update = false): Observable<Taxonomy> {
    const projectId = this.contextService.currentProjectId;
    // Clear cache
    this._clearTablesFromEntryPointsCache(projectId);
    this.mappedConcepts$.delete(projectId);

    const formData = new FormData();
    formData.append('update', update ? 'true' : 'false');
    formData.append('file', FileUtils.sanitizeFile(taxonomyFile));
    return this.httpClient.post<Taxonomy>(`${this.taxonomyUrl}/import`, formData);
  }

  public deleteTaxonomy(taxonomyId: number): Observable<void> {
    const projectId = this.contextService.currentProjectId;
    // Clear cache
    this._clearTablesFromEntryPointsCache(projectId);

    return this.httpClient.delete<void>(`${this.taxonomyUrl}/${taxonomyId}`);
  }

  public selectTaxonomy(packageId: number, selectedEntryPointName: string): Observable<CheckTaxonomyResult> {
    return this.httpClient.post<CheckTaxonomyResult>(`${this.taxonomyUrl}/select`, {
      packageId,
      selectedEntryPointName,
    });
  }

  public cancelImport(projectEntryPointId: number, packageId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.taxonomyUrl}/cancelImport`, {
      packageId,
      projectEntryPointId,
    });
  }

  public saveDictionary(
    packageId: number,
    projectEntryPointId: number,
    selectedEntryPointName: string,
    dicoName: string,
    fileName: string,
    applyXsdMigration = false
  ): Observable<IAssetResponse> {
    const params: HttpParams = new HttpParams().set('spinner', 'none');
    return this.httpClient.post<IAssetResponse>(
      `${this.taxonomyUrl}/saveDictionary`,
      {
        packageId,
        projectEntryPointId,
        selectedEntryPointName,
        dicoName,
        fileName,
        applyXsdMigration,
      },
      { params }
    );
  }

  private _clearTablesFromEntryPointsCache(projectId: number): void {
    this.mappedTablesFromEntryPoints$.delete(`${projectId}false`);
    this.mappedTablesFromEntryPoints$.delete(`${projectId}true`);

    this.clearTextBlocks(projectId);

    const arrayType = Object.values(XBRLType);
    arrayType.forEach(type => {
      this.mappedFilteredConcepts$.delete(type + projectId);
    });
  }

  public clearTextBlocks(projectId: number | null = null): void {
    if (!projectId) {
      projectId = this.contextService.currentProjectId;
    }
    this.mappedFilteredConcepts$.delete(XBRLType.TEXT_BLOCK + projectId);
    this.mappedFilteredConcepts$.delete(XBRLType.TEXT_BLOCK_2021 + projectId);
    this.mappedTextBlockTableConcepts$.delete(projectId);
  }

  //
  // XBRL
  //
  public importXbrl(xbrlFile: File, update = false, initialFileName?: string): Observable<CheckXbrlResult> {
    const formData = new FormData();
    formData.append(ParamImportDataEnum.FILE, FileUtils.sanitizeFile(xbrlFile));
    formData.append(ParamImportDataEnum.IMPORT_TABLE, 'false');
    formData.append(ParamImportDataEnum.UPDATE, update ? 'true' : 'false');
    formData.append(ParamImportDataEnum.LANG, this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '');
    this.clearFactCache();
    if (update && initialFileName) {
      formData.append(ParamImportDataEnum.FILE_NAME_TO_UPDATE, xbrlFile.name);
      formData.append(ParamImportDataEnum.INITIAL_FILE, initialFileName);
      return this.httpClient.post<CheckXbrlResult>(`${this.xbrlUrl}/replaceData`, formData);
    }
    return this.httpClient.post<CheckXbrlResult>(`${this.xbrlUrl}/import`, formData);
  }

  //
  // XHTML / IXBRL
  //
  public importXhtml(xhtmlFile: File, initialFileId: number, update = false): Observable<CheckXbrlResult> {
    const formData = new FormData();
    formData.append(ParamImportDataEnum.FILE, FileUtils.sanitizeFile(xhtmlFile));
    formData.append(ParamImportDataEnum.IMPORT_TABLE, 'true');
    formData.append(ParamImportDataEnum.UPDATE, update ? 'true' : 'false');
    formData.append(ParamImportDataEnum.LANG, this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '');
    this.clearFactCache();
    if (update) {
      formData.append(ParamImportDataEnum.INITIAL_FILE_ID, initialFileId.toString());
      return this.httpClient.post<CheckXbrlResult>(`${this.xbrlUrl}/replaceData`, formData);
    }
    return this.httpClient.post<CheckXbrlResult>(`${this.xbrlUrl}/import`, formData);
  }

  public getFactsResultsFromXbrlImport(lang: string | null = null): Observable<RCSFFile> {
    return this.httpClient.get<RCSFFile>(`${this.factListUrl}/tableasrcsf/${lang?.substring(0, 2)}`);
  }

  public getRcsfFileAndSheets(): Observable<IFilesAndSheetsNames> {
    return this.httpClient.get<any>(`${this.rcsfUrl}/filesAndSheets`);
  }

  public getRcsfFileData(fileSelected: string, deletedFile: boolean, displayDeletedSheets = false): Observable<RCSFFile> {
    let params: HttpParams = new HttpParams();
    if (deletedFile) {
      params = params.set('deletedFile', 'true');
    } else if (displayDeletedSheets) {
      params = params.set('displayDeletedSheets', 'true');
    }
    return this.httpClient
      .get<RCSFFile>(encodeURI(`${this.rcsfUrl}/assets/${fileSelected}/sheets`), { params })
      .pipe(
        tap((result: RCSFFile) => {
          const deletedSheets = result?.deletedSheets;
          if (deletedSheets && (deletedSheets?.length ?? 0) > 0) {
            result.sheets.forEach((sheet: RCSFSheet) => (sheet.deleted = deletedSheets.includes(sheet.sheetName)));
          }
        })
      );
  }

  public getRcsfSheetData(fileSelected: string, sheetName: string, withEmptyColumns = true): Observable<RCSFFile> {
    if ((fileSelected as AssetFileType) === AssetFileType.XBRL || (fileSelected as AssetFileType) === AssetFileType.XML) {
      return EMPTY;
    }

    const params: HttpParams = new HttpParams().set('withEmptyColumns', withEmptyColumns.toString());
    const key = `${fileSelected}/${sheetName}/${params.get('withEmptyColumns')}`;
    if (!this.mappedRcsfSheets$.has(key)) {
      this.mappedRcsfSheets$.set(
        key,
        this.httpClient
          .get<RCSFFile>(encodeURI(`${this.rcsfUrl}/assets/${fileSelected}/sheets/${sheetName}`), { params })
          .pipe(publishReplay(1), refCount())
      );
    }

    return this.mappedRcsfSheets$.get(key) as Observable<RCSFFile>;
  }

  //
  // Concepts
  //
  public getTablesFromEntryPoint(onlyMappableConcepts = false): Observable<IEntryPointTable[]> {
    const projectId = this.contextService.currentProjectId + String(onlyMappableConcepts);
    if (!this.mappedTablesFromEntryPoints$.has(projectId)) {
      const params: HttpParams = new HttpParams().set('skipError', 'true').set('onlyMappableConcepts', String(onlyMappableConcepts));
      this.mappedTablesFromEntryPoints$.set(
        projectId,
        this.httpClient
          .get<IEntryPointTable[]>(`${this.taxonomyUrl}/concepts`, { params })
          .pipe(
            catchError(() => of([])),
            publishReplay(1),
            refCount()
          )
      );
      setTimeout(() => this.mappedTablesFromEntryPoints$.delete(projectId), this.CACHE_DURATION);
    }
    return this.mappedTablesFromEntryPoints$.get(projectId) as Observable<IEntryPointTable[]>;
  }

  public getFilteredConcepts(filteredType: XBRLType): Observable<IEntryPointTable[]> {
    const mappedKey = filteredType + this.contextService.currentProjectId;
    if (!this.mappedFilteredConcepts$.has(mappedKey)) {
      const params: HttpParams = new HttpParams().set('skipError', 'true');
      this.mappedFilteredConcepts$.set(
        mappedKey,
        this.httpClient
          .get<IEntryPointTable[]>(`${this.taxonomyUrl}/type/${filteredType}`, { params })
          .pipe(
            catchError(() => of([])),
            publishReplay(1),
            refCount()
          )
      );
      setTimeout(() => this.mappedFilteredConcepts$.delete(mappedKey), this.CACHE_DURATION);
    }
    return this.mappedFilteredConcepts$.get(mappedKey) as Observable<IEntryPointTable[]>;
  }

  public getConcepts(): Observable<IConcept[]> {
    const projectId = this.contextService.currentProjectId;
    if (!this.mappedConcepts$.has(projectId)) {
      this.mappedConcepts$.set(
        projectId,
        this.httpClient.get<IConcept[]>(`${this.taxonomyUrl}/concepts/list`).pipe(publishReplay(1), refCount())
      );
      setTimeout(() => this.mappedConcepts$.delete(projectId), this.CACHE_DURATION);
    }
    return this.mappedConcepts$.get(projectId) as Observable<IConcept[]>;
  }

  public getConceptInstants(): Observable<IDate[]> {
    return this.httpClient.get<IDate[]>(
      `${this.taxonomyUrl}/concepts/periods/instant/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`
    );
  }

  public getDimensions(projectEntryPointId: number, conceptId: number, parentId: number): Observable<IAxis[]> {
    return this.httpClient.get<IAxis[]>(
      `${this.taxonomyUrl}/dimensions/tables/${projectEntryPointId}/concepts/${conceptId}/parent/${parentId}`
    );
  }

  public getConceptDurations(): Observable<IDate[]> {
    return this.httpClient.get<IDate[]>(
      `${this.taxonomyUrl}/concepts/periods/duration/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`
    );
  }

  public getConceptMonetaryUnits(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${this.taxonomyUrl}/concepts/monetaryUnits/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`
    );
  }

  //
  // Validate XBRL dictionary
  //
  public getValidation(): Observable<ValidateReportResultDTO> {
    return this.httpClient.get<ValidateReportResultDTO>(
      `${this.xbrlUrl}/validate/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`
    );
  }

  //
  // deleteSource
  //
  public deleteSource(selectedFile: { name: string; type: AssetFileType; id?: number }, cleanAll = true): Observable<IAssetResponse> {
    const formData = new FormData();
    if (selectedFile.id) {
      formData.append('fileId', selectedFile.id?.toString());
    } // bad : prefer fileId
    else if (selectedFile.name) {
      formData.append('fileName', selectedFile.name);
    }
    formData.append('cleanAll', cleanAll.toString());
    if (cleanAll) {
      this.clearFactCache();
    }
    switch (selectedFile.type) {
      case AssetFileType.XML:
      case AssetFileType.XBRL:
        formData.append('fileType', selectedFile.type);
        return this.httpClient.post<IAssetResponse>(`${this.xbrlUrl}/delete`, formData);
      case AssetFileType.XHTML:
      case AssetFileType.HTML:
        formData.append('fileType', 'IXBRL'); // see DamDirectorysEnum.java
        return this.httpClient.post<IAssetResponse>(`${this.xbrlUrl}/delete`, formData);
      case AssetFileType.TAXONOMY:
        formData.append('fileType', selectedFile.type);
        return this.httpClient.post<IAssetResponse>(`${this.taxonomyUrl}/delete`, formData);
      case AssetFileType.RCSF:
      case AssetFileType.XLS:
      case AssetFileType.XLSX:
        if (selectedFile.name) {
          this.clearRcsfFileCache(selectedFile.name);
        } else {
          this.clearRcsfSheetsCache();
        }
        formData.append('fileType', 'XLS'); // see DamDirectorysEnum.java
        return this.httpClient.post<IAssetResponse>(`${this.rcsfUrl}/delete`, formData);
      default:
        return of({} as IAssetResponse);
    }
  }

  //
  // Facts
  //
  public getFacts(): Observable<IFact[]> {
    const projectId = this.contextService.currentProjectId;
    if (!this.mappedFacts$.has(projectId)) {
      this.mappedFacts$.set(
        projectId,
        this.httpClient
          .get<IFact[]>(`${this.factListUrl}/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`)
          .pipe(publishReplay(1), refCount())
      );
    }
    return this.mappedFacts$.get(projectId) as Observable<IFact[]>;
  }

  public getFactById(factId: string): Observable<IFact> {
    return this.getFacts().pipe(exhaustMap(facts => of(facts?.find(({ factXbrlId }) => factXbrlId === factId) ?? {})));
  }

  public getFactByRoles(isTextBlock = false, isTextFact = false): Observable<IFactsByRole> {
    const projectId = this.contextService.currentProjectId;
    if (isTextBlock) {
      return this.getTextBlocksTable();
    } else if (isTextFact) {
      return this.getTextFactsTable();
    } else {
      if (!this.mappedFactsByRoles$.has(projectId)) {
        this.mappedFactsByRoles$.set(
          projectId,
          this.httpClient
            .get<IFactsByRole>(`${this.factListUrl}/tables/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`)
            .pipe(publishReplay(1), refCount())
        );
      }
      return this.mappedFactsByRoles$.get(projectId) as Observable<IFactsByRole>;
    }
  }

  /**
   * disabled concept list in xbrl data tagger
   * the list content concept used has:
   *  - editorial text fact if "disableEditorialFact" params is true
   *  - string text fact if "disableEditorialFact" params is false
   */
  public getConceptDisabledList(disableEditorialFact: boolean): Observable<IConcept[]> {
    const params: HttpParams = new HttpParams().set('disableEditorialFact', disableEditorialFact ? 'true' : 'false');
    return this.httpClient.get<IConcept[]>(
      `${this.taxonomyUrl}/concepts/disabled-list/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`,
      { params }
    );
  }

  public createFact(fact: IUpdateFact): Observable<IUpdateFactResponse> {
    this.clearFactCache();
    return this.httpClient.post<IUpdateFactResponse>(
      `${this.factListUrl}/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`,
      fact
    );
  }

  public setFactValue(factValue: IFactValueUpdate): Observable<void> {
    this.clearFactCache();
    return this.httpClient.put<void>(`${this.factUrl}`, factValue);
  }

  /**
   * return the list of fact usage ("IDeleteFactResponse[]") if check = true
   * use check param "false" to remove a fact
   */
  public deleteFactValue(factId: number, check = true): Observable<IDeleteFactResponse[]> {
    if (!check) {
      this.clearFactCache();
    }
    const params = new HttpParams().set('check', check ? 'true' : 'false');
    return this.httpClient.delete<IDeleteFactResponse[]>(`${this.factUrl}/${factId}`, { params });
  }

  public tagNewFact(factValue: IUpdateFact, conceptType: XBRLType): Observable<IFact> {
    this.clearFactCache();
    const documentLanguage = this.contextService.currentDocumentContext.language.code?.substring(0, 2);
    return this.httpClient.post<IFact>(`${this.factUrl}/${conceptType}/${documentLanguage}`, factValue);
  }

  //
  // Mapping
  //
  public importMappingFile(mappingFile: File): Observable<AutomaticMapping> {
    const params: HttpParams = new HttpParams().set('skipError', 'true');
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(mappingFile));
    this.clearFactCache();
    this.clearRcsfSheetsCache();
    return this.httpClient.post<AutomaticMapping>(`${this.mappingUrl}/import`, formData, { params });
  }

  public mapRcsfValue(filename: string, fact: IFact, scaleImport: number): Observable<IFact> {
    this.clearFactCache();
    this.clearRcsfSheetsCache();
    return this.httpClient.put<IFact>(`${this.mappingUrl}/${fact.concept?.type}`, this._factToMappingLine(filename, fact, scaleImport));
  }

  public unmapRcsfValue(filename: string, fact: IFact): Observable<void> {
    this.clearFactCache();
    this.clearRcsfSheetsCache();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this._factToMappingLine(filename, fact),
    };
    return this.httpClient.delete<void>(`${this.mappingUrl}`, options);
  }

  public clearFactCache(): void {
    const projectId = this.contextService.currentProjectId;
    this.mappedFacts$.delete(projectId);
    this.mappedFactsByRoles$.delete(projectId);
    this.mappedTextBlock$.delete(projectId);
    // rcsf sheet may content facts
    this.clearRcsfSheetsCache();
  }

  //
  // RCSF
  //

  public clearRcsfSheetsCache(): void {
    this.mappedRcsfSheets$.clear();
  }

  public clearRcsfFileCache(fileSelected: string): void {
    for (const fileselectedSheetname of this.mappedRcsfSheets$.keys()) {
      if (fileselectedSheetname.indexOf(fileSelected) === 0) {
        this.mappedRcsfSheets$.delete(fileselectedSheetname);
      }
    }
  }

  public clearRcsfCache(fileSelected: string, sheetname: string): void {
    this.mappedRcsfSheets$.delete(`${fileSelected}/${sheetname}/true`);
    this.mappedRcsfSheets$.delete(`${fileSelected}/${sheetname}/false}`);
  }

  public importRcsf(
    rcsfFile: File,
    projectId: number,
    scale: number,
    sheetList: ISheetList[],
    fileToUpdate?: string
  ): Observable<CheckRcsfImportResult> {
    const formData = new FormData();
    formData.append('rcsfFile', FileUtils.sanitizeFile(rcsfFile));
    formData.append('scale', scale.toString());
    formData.append('sheetLists', JSON.stringify(sheetList));
    formData.append('update', fileToUpdate ? 'true' : 'false');

    if (fileToUpdate) {
      this.clearRcsfFileCache(fileToUpdate);
      formData.append('initialFile', fileToUpdate);
      return this.httpClient.put<CheckRcsfImportResult>(`${this.rcsfUrl}/replaceData`, formData);
    }
    this.clearRcsfFileCache(rcsfFile.name);
    return this.httpClient.post<CheckRcsfImportResult>(`${this.rcsfUrl}/import`, formData);
  }

  public setRCSFData(rcsfData: IRCSFDataUpdated): Observable<RCSFImportData> {
    const sheetsToClean = new Map();
    sheetsToClean.set(rcsfData.filename.concat('-=-', rcsfData.sheetName), { filename: rcsfData.filename, sheetname: rcsfData.sheetName });
    this.clearRcsfCache(rcsfData.filename, rcsfData.sheetName);
    if (rcsfData.factId) {
      this.clearFactCache();
    }
    return this.httpClient.put<RCSFImportData>(`${this.rcsfUrl}/cellValue`, rcsfData).pipe(
      map((response: RCSFImportData) => {
        // Cells and related facts are now returned in response.factUpdateResult.propagatedCells
        if (response.factUpdateResult.propagatedCells) {
          response.factUpdateResult.propagatedCells.forEach((cell: RCSFCell) => {
            const key = cell.filename.concat('-=-', cell.sheetName);
            if (!sheetsToClean.has(key)) {
              sheetsToClean.set(key, { filename: cell.filename, sheetname: cell.sheetName });
              this.clearRcsfCache(cell.filename, cell.sheetName);
              if (rcsfData.factId && response.factUpdateResult.numUpdatedFacts && response.factUpdateResult.numUpdatedFacts === 1) {
                cell.fact = response.factUpdateResult.updatedFacts?.[0] ?? null;
              }
            }
          });
        }
        return response;
      })
    );
  }

  public checkDuplicateFact(factData: IFactReferenceToCheck): Observable<IFactDTO> {
    factData = {
      ...factData,
      importScale:
        typeof factData.importScale !== 'undefined' ? factData.importScale : this.contextService.currentDocumentContext.projectScale,
    };
    return this.httpClient.put<any>(`${this.rcsfUrl}/checkDuplicateFact`, factData);
  }

  public updateDuplicateValue(factXbrlId: string, currentCell: IRCSFDataUpdated | IFactValueUpdate): Observable<ICellSelectionDTO[]> {
    const reqBody: IRCSFDataUpdated | IFactValueUpdate = {
      ...currentCell,
      factXbrlId,
    };
    return this.httpClient.put<any>(`${this.rcsfUrl}/duplicatedCellFactUpdate`, reqBody);
  }

  private _factToMappingLine(filename: string, fact: IFact, scaleImport?: number): IMappingLine {
    if (!fact.selection?.single || (!fact.selection.rowIndex && fact.selection.rowIndex !== 0) || !fact.context?.period || !fact.concept) {
      return {} as IMappingLine;
    }

    const mappingLine: IMappingLine = {
      filename,
      balance: BalanceType.CREDIT, // TODO: handle balance in mapping interface ?
      colLetter: fact.selection.single.split(/(\d+)/)[0],
      rowNumber: fact.selection.rowIndex + 1,
      periodType: fact.context.period.periodType,
      startDate: fact.context.period.startDate,
      endDate: fact.context.period.endDate,
      sheetName: SpreadsheetUtils.cleanSheetname(fact.selection.sheetName),
      oimUnit: fact.oimUnit ?? undefined,
      qname: fact.concept.qname,
      userLabel: '',
      scale: scaleImport ?? this.contextService.currentDocumentContext.projectScale,
      entityIdentifier: null,
      language: fact.language ?? '',
      dimMappings: null,
    };

    if (fact.context.dimensions.length > 0 && checkDimensionsFilled(fact)) {
      mappingLine.dimMappings = fact.context.dimensions.map(dimension => ({
        axisQname: dimension.dimAxis.qname,
        memberQnames: dimension.dimMember.qname,
        memberValue: undefined,
      }));
    }
    return mappingLine;
  }

  /**
   * generate FactXbrlId with an IConcet and IConcept
   * formats :
   * Date : "ifrs-full:DateOfEndOfReportingPeriod2013##213800A7FZUWPHEH9F95#DURATION#2018-01-01#2019-12-31"
   * String : "ifrs-full:AddressOfRegisteredOfficeOfEntity#fr##213800A7FZUWPHEH9F95#DURATION#2017-01-01#2017-12-31",
   * Numeric : "ifrs-full:ProfitLossFromContinuingOperations##iso4217:EUR#213800A7FZUWPHEH9F95#DURATION#2017-01-01#2017-12-31",
   * Numeric (without unit): "ifrs-full:ProfitLossFromContinuingOperations##xbrli:pure#213800A7FZUWPHEH9F95#DURATION#2017-01-01#2017-12-31",
   * PerShare : "ifrs-full:DilutedEarningsLossPerShare##iso4217:EUR/xbrli:shares#213800A7FZUWPHEH9F95#DURATION#2017-10-01#2018-09-30"
   */
  public getFactXbrlId(fact: IFact): string {
    if (!fact.concept || !fact.context?.period) {
      return '';
    }
    const {
      concept: { qname },
      context: {
        dimensions,
        period: { startDate, endDate, periodType },
      },
    } = fact;

    const oimUnit = Object.keys(XbrlNumericTypes).includes(fact?.concept?.type) && !fact.oimUnit ? XBRLUnit.PURE : fact.oimUnit;

    const allowedTypeForLanguage = [XBRLType.STRING, XBRLType.TEXT_BLOCK, XBRLType.TEXT_BLOCK_2021];
    let language = '';
    if (allowedTypeForLanguage.includes(fact?.concept?.type)) {
      // Labrador wants to tag stringItem as pure and with document language
      language = this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '';
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const tokens: string = [periodType, startDate.toString(), endDate.toString()]
      .concat(
        dimensions
          ?.filter(dim => dim?.dimAxis?.qname && dim?.dimMember?.qname)
          ?.map(({ dimAxis, dimMember }) => `${dimAxis.qname}#${dimMember.qname}`)
          ?.join('#')
      )
      .filter((token: string) => !!token)
      .join('#');

    return `${qname}#${language}#${oimUnit ?? ''}#${this.contextService.currentDocumentContext?.legalIdentifiers}#${tokens}`;
  }

  public updateFactXbrlId(factID: string): string {
    const splitFactID = factID.split('#');

    // Update language
    splitFactID[1] = this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '';

    // LEI
    splitFactID[3] = this.contextService.currentDocumentContext?.legalIdentifiers;

    return splitFactID.join('#');
  }

  // TEXT BLOCK
  public getTextBlocks(force: boolean): Observable<IFact[]> {
    const projectId = this.contextService.currentProjectId;
    if (!this.mappedTextBlock$.has(projectId) || force) {
      this.mappedTextBlock$.set(
        projectId,
        this.httpClient
          .get<IFact[]>(`${this.factListUrl}/text-block/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`)
          .pipe(publishReplay(1), refCount())
      );
    }
    return this.mappedTextBlock$.get(projectId) as Observable<IFact[]>;
  }

  public getTextBlockById(factId: string, force: boolean): Observable<IFact> {
    return this.getTextBlocks(force).pipe(
      exhaustMap(facts => of(facts?.find(({ factXbrlId }) => factXbrlId === factId)))
    ) as Observable<IFact>;
  }

  public getTextBlocksTable(): Observable<IFactsByRole> {
    const projectId = this.contextService.currentProjectId;
    if (!this.mappedTextBlockTableConcepts$.has(projectId)) {
      this.mappedTextBlockTableConcepts$.set(
        projectId,
        this.httpClient
          .get<IFactsByRole>(
            `${this.factListUrl}/text-block/tables/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`
          )
          .pipe(publishReplay(1), refCount())
      );
    }
    return this.mappedTextBlockTableConcepts$.get(projectId) as Observable<IFactsByRole>;
  }

  public getTextFactsTable(): Observable<IFactsByRole> {
    return this.httpClient
      .get<IFactsByRole>(
        `${this.factListUrl}/string-concepts/tables/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`
      )
      .pipe(publishReplay(1), refCount());
  }

  /**
   * check if the fact is multi-indexed (other use somewhere else)
   * @param factXbrlId (see getFactXbrlId method format for factXbrlId )
   */
  public isMultiIndexedFact(factXbrlId: string): Observable<{ isMultiIndex: boolean }> {
    const params = new HttpParams().set('factKey', factXbrlId);
    return this.httpClient.get<{ isMultiIndex: boolean }>(this.multiIndexUrl, { params });
  }

  public getTextFactById(factId: string): Observable<IFact> {
    return this.getTextFacts().pipe(exhaustMap(facts => of(facts?.find(({ factXbrlId }) => factXbrlId === factId)))) as Observable<IFact>;
  }

  public getTextFacts(): Observable<IFact[]> {
    // No cache for text fact
    return this.httpClient
      .get<IFact[]>(`${this.factListUrl}/${this.contextService.currentDocumentContext.language.code?.substring(0, 2)}`)
      .pipe(publishReplay(1), refCount());
  }

  /**
   * containsRules: true if a correspondence table is imported or if fact were mapped in eol data
   */
  public checkCorrespondenceTable(): Observable<{ containsRules: boolean }> {
    return this.httpClient.get<{ containsRules: boolean }>(`${this.checkCorrespondenceTableUrl}`);
  }

  public downloadCorrespondenceTable(): string {
    return this.exportCorrespondenceTableUrl;
  }
}
