import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { StyleCategory } from 'app/shared/enum/style-category.enum';
import { StyleProperty } from 'app/shared/enum/style-property.enum';

import { IColor } from 'app/shared/model/color.model';
import { IFont, IFontFace, IFontFamily, IFontGroup } from 'app/shared/model/font.model';
import {
  FOOTNOTE_CATEGORIES,
  ICssImportResponse,
  IMatrixCell,
  IMatrixLine,
  IStyle,
  IStyleProperty,
  STYLE_PROP_TYPE,
  SUMMARY_CATEGORIES,
} from 'app/shared/model/style.model';
import { ISpecialChar } from 'app/shared/model/special-char.model';
import { ContextService } from './context.service';
import { IIdmlImportResponse } from 'app/shared/model/idml-import-response.model';
import { AccountService } from '../auth/account.service';
import { FileUtils } from 'app/shared/util/file-utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  public resourceUrl = `${SERVER_API_URL}api/projects`;

  // css content cache
  public cssEolStyle: string;
  public cssPaginationHeaders: string;
  public cssPaginationPages: { [key: string]: string };
  public cssForPrint: { [key: string]: string };

  // Matrix color subject
  private matrixSubject: Subject<IMatrixLine[]> = new Subject();

  private _actualMatrix: IMatrixLine[] = [];

  private readonly duplicateSuffixFr = 'COPIE';
  private readonly duplicateSuffixEn = 'COPY';

  constructor(
    protected http: HttpClient,
    protected contextService: ContextService,
    protected accountService: AccountService,
    private translate: TranslateService
  ) {}

  public getListOfProjectStyles(): Observable<IStyle[]> {
    const id = this.contextService.currentProjectId;
    return this.http.get<IStyle[]>(`${this.resourceUrl}/${id}/styles`);
  }

  public getProjectStyle(styleId: number): Observable<IStyle> {
    const projectId = this.contextService.currentProjectId;
    return this.http.get<IStyle>(`/api/projects/${projectId}/styles/${styleId}`);
  }

  public getProjectStyleByCategory(category: string): Observable<IStyle[]> {
    const projectId = this.contextService.currentProjectId;
    return this.http.get<IStyle[]>(`/api/projects/${projectId}/styles/category/${category}`).pipe(
      map((styles: IStyle[]) => {
        if ((category as StyleCategory) === StyleCategory.FOOTNOTE) {
          return styles.sort(
            (style1: IStyle, style2: IStyle) => FOOTNOTE_CATEGORIES.indexOf(style1.category) - FOOTNOTE_CATEGORIES.indexOf(style2.category)
          );
        } else if ((category as StyleCategory) === StyleCategory.SUMMARY) {
          return styles.sort(
            (style1: IStyle, style2: IStyle) => SUMMARY_CATEGORIES.indexOf(style1.category) - SUMMARY_CATEGORIES.indexOf(style2.category)
          );
        } else {
          return styles;
        }
      })
    );
  }

  // Font
  public getSpecialChar(): Observable<Map<string, ISpecialChar[]>> {
    const id = this.contextService.currentProjectId;
    return this.http.get<Map<string, ISpecialChar[]>>(`${this.resourceUrl}/${id}/fonts/map`);
  }

  public getFontList(): Observable<IFontGroup[]> {
    const id = this.contextService.currentProjectId;
    return this.http.get<IFontGroup[]>(`${this.resourceUrl}/${id}/fonts`);
  }

  public uploadFont(file: File): Observable<IFont[]> {
    const id = this.contextService.currentProjectId;
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));
    const params: HttpParams = new HttpParams().set('skipError', 'true');

    return this.http.post<IFont[]>(`${this.resourceUrl}/${id}/fonts/upload`, formData, { params });
  }

  public deleteFontGroup(name: string): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.delete<void>(`${this.resourceUrl}/${id}/fonts/${name}`);
  }

  public deleteFont(fontId: number): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.delete<void>(`${this.resourceUrl}/${id}/font/${fontId}`);
  }

  public reassignFont(oldfontId: number, newFontId: number): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fonts/${oldfontId}/initialFontId/${newFontId}`, {});
  }

  public updateFont(font: IFont): Observable<IFont> {
    const id = this.contextService.currentProjectId;
    return this.http.put<IFont>(`${this.resourceUrl}/${id}/fonts/${font.id}`, font);
  }

  public updateSpecialCharFont(font: IFont): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fonts/${font.id}/special/${font.specialCharacters}`, {});
  }

  // FontFamilies
  public getFontFamilyList(): Observable<IFontFamily[]> {
    const id = this.contextService.currentProjectId;
    return this.http.get<IFontFamily[]>(`${this.resourceUrl}/${id}/fontfamilies`);
  }

  public deleteFontFamily(idFontFamily: number): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.delete<void>(`${this.resourceUrl}/${id}/fontfamilies/${idFontFamily}`);
  }

  public updateFontFamily(fontFamily: IFontFamily): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fontfamilies/${fontFamily.id}`, fontFamily);
  }

  public updateFontFamilyName(fontFamily: IFontFamily): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fontfamilies/${fontFamily.id}/name`, fontFamily.name);
  }

  public updateFontFamilyAdvanced(fontFamily: IFontFamily): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fontfamilies/${fontFamily.id}/advanced/${fontFamily.advanced}`, {});
  }

  public setDefaultFontFamily(fontFamilyId: number): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fontfamilies/${fontFamilyId}/default`, {});
  }

  public createFontFamily(fontFamily: IFontFamily): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.post<void>(`${this.resourceUrl}/${id}/fontfamilies`, fontFamily);
  }

  // FontFace
  public updateFontFace(fontFace: IFontFace, fontFamilyId: number): Observable<void> {
    const id = this.contextService.currentProjectId;
    return this.http.put<void>(`${this.resourceUrl}/${id}/fontfamilies/${fontFamilyId}/fontfaces/${fontFace.id}`, fontFace);
  }

  // Colors
  public getColors(): Observable<IColor[]> {
    const id = this.contextService.currentProjectId;
    return this.http.get<IColor[]>(`${this.resourceUrl}/${id}/colors`);
  }

  // Colors
  public getAllColors(): Observable<IColor[]> {
    const id = this.contextService.currentProjectId;
    return this.http.get<IColor[]>(`${this.resourceUrl}/${id}/allcolors`);
  }

  public createColor(color: IColor): Observable<IColor> {
    const id = this.contextService.currentProjectId;
    return this.http.post<IColor>(`${this.resourceUrl}/${id}/colors`, color);
  }

  public updateColor(color: IColor): Observable<IColor> {
    const id = this.contextService.currentProjectId;
    return this.http.patch<IColor>(`${this.resourceUrl}/${id}/colors/${color.id}`, color);
  }

  public deletecolor(ids: number[]): Observable<IColor> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ids },
    };
    return this.http.delete<IColor>(`${this.resourceUrl}/${this.contextService.currentProjectId}/colors/bulkDelete`, options);
  }

  // Css upload
  public importCss(file: File): Observable<ICssImportResponse> {
    const id = this.contextService.currentProjectId;
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));
    const params: HttpParams = new HttpParams().set('skipError', 'true');

    return this.http.post<ICssImportResponse>(`${this.resourceUrl}/${id}/styles/import`, formData, {
      headers: { anonymous: 'true' },
      params,
    });
  }

  // Idml upload
  public importIdml(file: File): Observable<IIdmlImportResponse> {
    const id = this.contextService.currentProjectId;
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));
    const params: HttpParams = new HttpParams().set('skipError', 'true');

    return this.http.post<IIdmlImportResponse>(`${this.resourceUrl}/${id}/page-models`, formData, { params });
  }

  // XHTML Style upload
  public importXhtmlStyle(file: File): Observable<void> {
    const projectId = this.contextService.currentProjectId;
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));
    const params: HttpParams = new HttpParams().set('skipError', 'true');

    return this.http.post<void>(`${this.resourceUrl}/${projectId}/xhtml-style/import`, formData, { params });
  }

  public downloadXhtmlStyle(): void {
    const projectId = this.contextService.currentProjectId;
    this.http
      .get(`${this.resourceUrl}/${projectId}/xhtml-style/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(response => {
        if (response.body) {
          saveAs(new Blob([response.body], { type: 'text/css' }), projectId.toString());
        }
      });
  }

  public xhtmlStyleExists(): Observable<boolean> {
    const projectId = this.contextService.currentProjectId;
    return this.http.get<boolean>(`${this.resourceUrl}/${projectId}/xhtml-style/exists`);
  }

  public createStyle(style: IStyle): Observable<IStyle> {
    const params: HttpParams = new HttpParams().set('skipError', 'true');
    const projectId = this.contextService.currentProjectId;
    return this.http.post<IStyle>(`${this.resourceUrl}/${projectId}/styles`, style, {
      headers: { anonymous: 'true' },
      params,
    });
  }

  public updateProperty(style: IStyle, property: StyleProperty, value: string, propType = STYLE_PROP_TYPE.TEXT): IStyleProperty {
    const indexProperty = style.properties?.findIndex(prop => prop.name === property) ?? -1;
    const propertyUpdated = { name: property, data: value, type: propType };
    if (indexProperty > -1 && style.properties) {
      if (value === null) {
        style.properties = style.properties?.filter((styleProperty: IStyleProperty) => property !== styleProperty.name);
      } else {
        style.properties[indexProperty] = propertyUpdated;
      }
    } else if (value !== null && style.properties) {
      style.properties.push(propertyUpdated);
    }
    return propertyUpdated;
  }

  /**
   *
   * @param cssConf: If EOL_STYLE_MIN, the elements styles are not retrieved: only globals styles are returned
   */
  public getStyleSheet(cssConf = 'EOL_DEFAULT'): Observable<string> {
    const projectId = this.contextService.currentProjectId;
    return this.http.get(`${this.resourceUrl}/${projectId}/cssstyle?cssConf=${cssConf}&spinner=none`, { responseType: 'text' });
  }

  public getPreviewEolStyleSheet(): Observable<string> {
    return this.getStyleSheet('EOL_PREVIEW');
  }

  public getPreviewCssPaginationHeaders(sectionId: number): Observable<string> {
    const projectId = this.contextService.currentProjectId;
    const documentId = this.accountService.documentId;
    return this.http.get(
      `${this.resourceUrl}/${projectId}/documents/${documentId}/css/pagination-headers?documentaryUnitId=${sectionId}&spinner=none`,
      {
        responseType: 'text',
      }
    );
  }

  public getEditorStyleSheet(category: string, cssConf = 'EOL_DEFAULT'): Observable<string> {
    const projectId = this.contextService.currentProjectId;
    return this.http.get(`${this.resourceUrl}/${projectId}/cssstyle/style_editor/${category}?cssConf=${cssConf}&spinner=none`, {
      responseType: 'text',
    });
  }

  public getPreviewCssPaginationPages(sectionId: number): Observable<{ [key: string]: string }> {
    const projectId = this.contextService.currentProjectId;
    const documentId = this.accountService.documentId;
    const domain = `${window.location.protocol}//${window.location.host}`;
    const url = `${domain}/${this.resourceUrl}/${projectId}/documents/${documentId}/css/pagination-pages?documentaryUnitId=${sectionId}&spinner=none`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((cssString: string) => {
        return { [url]: cssString };
      })
    );
  }

  public getPreviewCssForPrint(): Observable<{ [key: string]: string }> {
    const domain = `${window.location.protocol}//${window.location.host}`;
    const url = `${domain}/content/css/css_for_print.css?spinner=none`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((cssString: string) => {
        return { [url]: cssString };
      })
    );
  }

  public getPreviewPagedJsCssForPrint(): Observable<{ [key: string]: string }> {
    const domain = `${window.location.protocol}//${window.location.host}`;
    const url = `${domain}/content/css/paged.css?spinner=none`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((cssString: string) => {
        return { [url]: cssString };
      })
    );
  }

  public cleanPreviewCache(): void {
    this.cssEolStyle = '';
    this.cssPaginationHeaders = '';
    this.cssPaginationPages = {};
    this.cssForPrint = {};
  }

  // COLOR-MATRIX
  public addMatrixLine(line: IMatrixLine): Observable<IMatrixLine> {
    const id = this.contextService.currentProjectId;
    return this.http.post<IMatrixLine>(`${this.resourceUrl}/${id}/color-matrix/line`, line);
  }

  getMatrixObservable(): Observable<IMatrixLine[]> {
    return this.matrixSubject.asObservable();
  }

  public setMatrixUI(data: IMatrixLine[]): void {
    this.actualMatrix = data;
    this.actualMatrix.sort((line1, line2) => ((line1.style?.name ?? '') < (line2.style?.name ?? '') ? -1 : 1));
    this.matrixSubject.next(this.actualMatrix);
  }

  public addMatrixLineUI(matrixLineToAdd: IMatrixLine): void {
    this.resetLastAddedLines();
    matrixLineToAdd.isLastAdded = true;
    this.actualMatrix.push(matrixLineToAdd);

    this.actualMatrix.sort((line1, line2) => ((line1.style?.name ?? '') < (line2.style?.name ?? '') ? -1 : 1));
    this.matrixSubject.next(this.actualMatrix);
  }

  private resetLastAddedLines(): void {
    this.actualMatrix.forEach((matrixLine: IMatrixLine) => (matrixLine.isLastAdded = false));
  }

  public deleteMatrixLinesUI(matrixLinesToDelete: IMatrixLine[]): void {
    this.resetLastAddedLines();
    matrixLinesToDelete.forEach((matrixLine: IMatrixLine) => {
      const index = this.actualMatrix.indexOf(matrixLine);
      if (index > -1) {
        this.actualMatrix.splice(index, 1);
      }
    });
    this.matrixSubject.next(this.actualMatrix);
  }

  public updateMatrixLineUI(matrixCellToUpdate: IMatrixCell[]): void {
    this.resetLastAddedLines();

    // Multiple lines can be affected
    matrixCellToUpdate.forEach((matrixCell: IMatrixCell) => {
      const index = this.actualMatrix.findIndex((matrixLine: IMatrixLine) => matrixCell.matrixLineId === matrixLine.id);
      if (index > -1) {
        const cells = this.actualMatrix[index].cells;
        if (cells) {
          const cellToUpdate = cells.find(cell => cell.id === matrixCell.id);

          if (cellToUpdate) {
            // update actual cell
            this.actualMatrix[index].cells?.splice(cells.indexOf(cellToUpdate), 1, matrixCell);
          } else {
            // add new cell
            this.actualMatrix[index].cells = cells.concat(matrixCell);
          }
        }
        this.matrixSubject.next(this.actualMatrix);
      }
    });
  }

  public get actualMatrix(): IMatrixLine[] {
    return this._actualMatrix;
  }

  public set actualMatrix(value: IMatrixLine[]) {
    this._actualMatrix = value;
  }

  public getColorMatrix(): Observable<IMatrixLine[]> {
    const id = this.contextService.currentProjectId;
    return this.http.get<IMatrixLine[]>(`${this.resourceUrl}/${id}/color-matrix`);
  }

  public addMatrixCells(cells: IMatrixCell[]): Observable<IMatrixCell[]> {
    const id = this.contextService.currentProjectId;
    return this.http.post<IMatrixCell[]>(`${this.resourceUrl}/${id}/color-matrix/cells`, cells);
  }

  public deleteMatrixLines(rows: IMatrixLine[]): Observable<void> {
    const id = this.contextService.currentProjectId;
    const ids = rows.map(row => row.id);

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ids },
    };

    return this.http.delete<void>(`${this.resourceUrl}/${id}/color-matrix/line`, options);
  }

  public deleteStyles(styles: IStyle[]): Observable<void> {
    const ids = styles.map(style => style.id);
    const projectId = this.contextService.currentProjectId;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ids },
    };

    return this.http.delete<void>(`${this.resourceUrl}/${projectId}/styles`, options);
  }

  public duplicateStyle(model: IStyle): Observable<IStyle> {
    const styleId = model.id;
    const projectId = this.contextService.currentProjectId;
    const duplicateStyleModelNameFr = `${model.nameFr}_${this.duplicateSuffixFr}`;
    const duplicateStyleModelNameEn = `${model.nameEn?.length ? model.nameEn : model.nameFr}_${this.duplicateSuffixEn}`;
    const params: HttpParams = new HttpParams().set('skipError', 'true');
    return this.http.post<IStyle>(
      `${this.resourceUrl}/${projectId}/style/${styleId}/duplicate`,
      {
        duplicateStyleModelNameFr,
        duplicateStyleModelNameEn,
      },
      { params }
    );
  }
}
