<div class="panel-block-left toc">
  <div class="panel-header-block">
    <mat-icon svgIcon="toc" class="toc-icon"></mat-icon>
    <div class="header-title" jhiTranslate="htmlEditor.toc.title"></div>
    <div class="header-actions">
      <ng-container *ngIf="!editMode && !configMode; else finishMode">
        <div class="header-action header-action-config-titles" (click)="toggleConfigMode()">
          <mat-icon [matTooltip]="'htmlEditor.toc.action.configTitles' | translate" svgIcon="round-toc-config"></mat-icon>
        </div>
        <ng-container *jhiHasAnyAuthority="[Authority.ADD_REMOVE_TOC_SECTION, Authority.SECTION_LOCK]">
          <div class="header-action" *ngIf="!editMode" (click)="toggleEditMode()">
            <mat-icon [matTooltip]="'htmlEditor.toc.action.edit' | translate" svgIcon="round-pen"></mat-icon>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #finishMode>
        <div class="header-action" *ngIf="configMode" (click)="toggleConfigMode()">
          <mat-icon svgIcon="reset"></mat-icon>
          <span jhiTranslate="htmlEditor.toc.action.finishConfig"></span>
        </div>
        <div class="header-action" *ngIf="editMode" (click)="toggleEditMode()">
          <mat-icon svgIcon="reset"></mat-icon>
          <span jhiTranslate="htmlEditor.toc.action.finish"></span>
        </div>
      </ng-template>
    </div>
    <button mat-icon-button class="header-close" (click)="close()">
      <mat-icon svgIcon="chevron-left"></mat-icon>
    </button>
  </div>
  <ng-container [formGroup]="classificationPLanForm" *ngIf="classificationPLanDocuments?.length > 0 && !configMode && !editMode">
    <div class="current-cat"><span jhiTranslate="htmlEditor.toc.category"></span> {{ classificationPLanDocuments[0]?.cpName }}</div>
    <div class="asset-management-block">
      <mat-form-field>
        <mat-label jhiTranslate="htmlEditor.toc.reportChoice"></mat-label>
        <mat-select formControlName="classificationPLan" hideSingleSelectionIndicator="true">
          <mat-option *ngFor="let doc of classificationPLanDocuments" [value]="doc.id" [disabled]="!doc.hasRight">
            <span>{{ doc.title }}</span>
            <span
              class="toc-open-document-in-new-tab"
              title="{{ 'htmlEditor.toc.openInNewTab' | translate }}"
              (click)="$event.stopPropagation(); openClassificationPLanDocument(doc.id, true)"
              *ngIf="doc.hasRight"
            >
              <mat-icon svgIcon="icon-open-new-tab"></mat-icon>
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <div class="panel-content-block" *ngIf="!configMode; else configModeTemplate">
    <div class="panel-content-action-mode" *ngIf="editMode">
      <div class="panel-content-action-buttons">
        <div
          class="panel-content-action-button"
          [class.selected]="addMode"
          (click)="toggleAddMode()"
          *jhiHasAnyAuthority="[Authority.ADD_REMOVE_TOC_SECTION]"
        >
          <mat-icon svgIcon="round-add"></mat-icon>
          <span jhiTranslate="htmlEditor.toc.action.add"></span>
        </div>
        <div
          class="panel-content-action-button"
          [class.selected]="graphicalMode"
          (click)="toggleGraphicalMode()"
          *jhiHasAnyAuthority="[Authority.ADD_REMOVE_TOC_SECTION]"
        >
          <mat-icon svgIcon="round-check"></mat-icon>
          <span jhiTranslate="htmlEditor.toc.action.graphical" [matTooltip]="'htmlEditor.toc.action.graphicalInfo' | translate"></span>
        </div>
        <div
          class="panel-content-action-button"
          [class.selected]="activationMode"
          (click)="toggleActivationMode()"
          *jhiHasAnyAuthority="[Authority.ADD_REMOVE_TOC_SECTION]"
        >
          <mat-icon svgIcon="round-check"></mat-icon>
          <span jhiTranslate="htmlEditor.toc.action.activate"></span>
        </div>
        <div
          class="panel-content-action-button"
          [class.selected]="lockMode"
          (click)="toggleLockMode()"
          *jhiHasAnyAuthority="[Authority.SECTION_LOCK]"
        >
          <mat-icon svgIcon="round-lock"></mat-icon>
          <span jhiTranslate="htmlEditor.toc.action.lock"></span>
        </div>
      </div>
      <div class="panel-content-action-synchro" *jhiHasAnyAuthority="[Authority.TOC_SYNC]">
        <div class="panel-content-button-synchro" (click)="synchronizeTOC()" [class.hasWrongTitle]="hasWrongTitle">
          <mat-icon svgIcon="round-refresh"></mat-icon>
          <div jhiTranslate="htmlEditor.toc.action.synchronize"></div>
        </div>
        <div>{{ synchonizedDate | localizedDate: 'short' }}</div>
      </div>
    </div>

    <div class="panel-content-document-properties" *ngIf="editMode">
      <span class="page-offset-label" jhiTranslate="htmlEditor.toc.pageOffset"></span>
      <mat-form-field class="page-offset-input">
        <input matInput type="number" name="pageOffset" [(ngModel)]="pageOffset" (ngModelChange)="updatePageOffset()" step="1" />
      </mat-form-field>
    </div>

    <mat-tree class="scrollbar" [dataSource]="dataSource" [treeControl]="treeControl" [class.editMode]="editMode">
      <mat-tree-node *matTreeNodeDef="let section">
        <div
          class="mat-tree-node"
          [matTooltip]="getTooltip(nodeElement, section)"
          matTooltipPosition="right"
          [ngClass]="{
            selected: (section.titleId == null && section.id === sectionId) || (section.titleId != null && section.titleId === titleId),
            opened: section.id !== sectionId && isSectionOpened(section),
            desactivated: !section.documentaryUnit.active,
            locked: lockMode && section.documentaryUnit.locked,
            'last-section': section.actionable || section.documentaryUnit.type == ContentType.COVER_I_II,
            'not-allowed': !section.hasReadRight && !section.hasWriteRight,
            'wrong-title': section.wrongTitle
          }"
        >
          <div
            (click)="onClickNode(section)"
            (contextmenu)="onRightClickNode(section)"
            #nodeElement
            [ngClass]="{ selectable: !section.children || section.children.length === 0 }"
          >
            <div class="position" *ngIf="section.numbering">{{ section.numbering }}</div>
            {{ section.title }}
          </div>
          <ng-container *ngIf="section.hasReadRight && !section.hasWriteRight">
            <ng-container *ngIf="section.documentaryUnit.locked; else titleRO">
              <mat-icon class="rights-icon locked" svgIcon="locked"></mat-icon>
            </ng-container>
            <ng-template #titleRO>
              <mat-icon class="rights-icon" svgIcon="read-only"></mat-icon>
            </ng-template>
          </ng-container>
          <mat-icon class="rights-icon" svgIcon="no-rights" *ngIf="!section.hasReadRight && !section.hasWriteRight"> </mat-icon>
          <ng-container *ngIf="section.actionable">
            <mat-icon
              class="rights-icon clickable"
              svgIcon="round-add"
              *ngIf="addMode"
              (click)="addAfter($event, section)"
              [matTooltip]="'htmlEditor.toc.action.addAfterTooltip' | translate"
            >
            </mat-icon>
            <mat-icon
              class="rights-icon clickable locked"
              svgIcon="locked"
              *ngIf="lockMode && section.documentaryUnit.locked"
              (click)="lock($event, section, false)"
              [matTooltip]="'htmlEditor.toc.action.unlockTooltip' | translate"
            ></mat-icon>
            <mat-icon
              class="rights-icon clickable unlocked"
              svgIcon="unlocked"
              *ngIf="lockMode && !section.documentaryUnit.locked"
              (click)="lock($event, section, true)"
              [matTooltip]="'htmlEditor.toc.action.lockTooltip' | translate"
            ></mat-icon>
            <mat-checkbox
              *ngIf="activationMode"
              [color]="
                (section.titleId == null && section.id === sectionId) || (section.titleId != null && section.titleId === titleId)
                  ? ''
                  : 'primary'
              "
              [checked]="section.documentaryUnit.active"
              [disableRipple]="true"
              [matTooltip]="'htmlEditor.toc.action.activateTooltip' | translate"
              (change)="activate($event, section)"
            >
            </mat-checkbox>
            <mat-checkbox
              *ngIf="graphicalMode && section.documentaryUnit.graphicalPagePdfName"
              color="primary"
              [checked]="section.documentaryUnit.taggedAsGraphicalPage"
              [disableRipple]="true"
              [matTooltip]="'htmlEditor.toc.action.graphicalTooltip' | translate"
              (change)="graphical($event, section)"
            >
            </mat-checkbox>
          </ng-container>
        </div>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let section; when: hasChild">
        <div
          class="mat-tree-node"
          [matTooltip]="getTooltip(nodeElement2, section)"
          matTooltipPosition="right"
          [ngClass]="{
            selected: (section.titleId == null && section.id === sectionId) || (section.titleId != null && section.titleId === titleId),
            opened: section.id !== sectionId && isSectionOpened(section),
            desactivated: !section.documentaryUnit.active,
            locked: lockMode && section.documentaryUnit.locked,
            'last-section': editMode && (section.actionable || !isExpanded[section.id]),
            'not-allowed': !section.hasReadRight && !section.hasWriteRight,
            'wrong-title': section.wrongTitle
          }"
        >
          <button class="expander" mat-icon-button (click)="toggleNode(section)">
            <mat-icon svgIcon="{{ isExpanded[section.id] ? 'fleche-down' : 'fleche-right' }}"></mat-icon>
          </button>

          <div
            class="title"
            (click)="onClickNode(section)"
            (contextmenu)="onRightClickNode(section)"
            #nodeElement2
            [ngClass]="{
              selectable: !section.children || section.children.length === 0,
              no_numbering: !section.numbering
            }"
          >
            <div class="position" *ngIf="section.numbering">{{ section.numbering }}</div>
            <div class="position" *ngIf="!section.numbering && section.defaultTitleNumber">{{ section.defaultTitleNumber }}</div>
            <span
              *ngIf="section?.documentaryUnit?.titles[0]?.htmlContent; else title"
              [innerHtml]="section.documentaryUnit.titles[0].htmlContent | safeHtml"
            ></span>
            <ng-template #title>
              {{ section.title }}
            </ng-template>
          </div>
          <mat-icon
            class="parent-with-selected-children-icon"
            svgIcon="oval"
            *ngIf="section.id === parentSectionId; else noChildren"
          ></mat-icon>
          <ng-template #noChildren>
            <div class="padding-right-level-1">&nbsp;</div>
          </ng-template>

          <ng-container *ngIf="section.hasReadRight && !section.hasWriteRight">
            <ng-container *ngIf="section.documentaryUnit.locked; else titleRO">
              <mat-icon class="rights-icon locked" svgIcon="locked"></mat-icon>
            </ng-container>
            <ng-template #titleRO>
              <mat-icon class="rights-icon" svgIcon="read-only"></mat-icon>
            </ng-template>
          </ng-container>
          <mat-icon class="rights-icon" svgIcon="no-rights" *ngIf="!section.hasReadRight && !section.hasWriteRight"> </mat-icon>
          <ng-container *ngIf="section.actionable">
            <mat-icon
              class="rights-icon clickable"
              svgIcon="round-add"
              *ngIf="addMode"
              (click)="addAfter($event, section)"
              [matTooltip]="'htmlEditor.toc.action.addAfterTooltip' | translate"
            >
            </mat-icon>
            <mat-icon
              class="rights-icon clickable locked"
              svgIcon="locked"
              *ngIf="lockMode && section.documentaryUnit.locked"
              (click)="lock($event, section, false)"
              [matTooltip]="'htmlEditor.toc.action.unlockTooltip' | translate"
            ></mat-icon>
            <mat-icon
              class="rights-icon clickable unlocked"
              svgIcon="unlocked"
              *ngIf="lockMode && !section.documentaryUnit.locked"
              (click)="lock($event, section, true)"
              [matTooltip]="'htmlEditor.toc.action.lockTooltip' | translate"
            ></mat-icon>
            <mat-checkbox
              *ngIf="activationMode"
              [color]="
                (section.titleId == null && section.id === sectionId) || (section.titleId != null && section.titleId === titleId)
                  ? ''
                  : 'primary'
              "
              [checked]="section.documentaryUnit.active"
              [disableRipple]="true"
              [matTooltip]="'htmlEditor.toc.action.activateTooltip' | translate"
              (change)="activate($event, section)"
            >
            </mat-checkbox>
            <mat-checkbox
              *ngIf="graphicalMode && section.documentaryUnit.graphicalPagePdfName"
              color="primary"
              [checked]="section.documentaryUnit.taggedAsGraphicalPage"
              [disableRipple]="true"
              [matTooltip]="'htmlEditor.toc.action.graphicalTooltip' | translate"
              (change)="graphical($event, section)"
            >
            </mat-checkbox>
          </ng-container>
        </div>
        <div [class.node-invisible]="!isExpanded[section.id]">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <ng-template #configModeTemplate>
    <jhi-titles-level-selection [tocConfig]="tocConfig" (finishSetup)="toggleConfigMode()"></jhi-titles-level-selection>
  </ng-template>
</div>
