import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Observable, Subscription, Subject, timer, EMPTY } from 'rxjs';
import { switchMap, mergeMap, retry, share } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { AMFValidateReportResult, ExportStatus, IDMEntry, PDFReportResult, ExportType } from 'app/shared/model/download-manager.model';
import { AccountService } from 'app/core/auth/account.service';

import { SnackbarService, SNACKBAR_TYPE } from 'app/core/service/snackbar.service';
import { ContextService } from './context.service';
import { Authority } from 'app/shared/enum/authority.enum';

const DOCUMENTS_API_URL = `${SERVER_API_URL}api/documents`;
const PROJECTS_API_URL = `${SERVER_API_URL}api/projects`;

const POLLING_FREQUENCY = 20; // seconds

@Injectable({ providedIn: 'root' })
export class DownloadManagerService {
  /**
   * Flag used to prevent the status notification from showing on page start/refresh
   */
  public isFirstCallDone = false;
  public resourceUrl: string;

  private subscriptions: Subscription = new Subscription();
  private fetchDownloads$ = new Subject<void>();

  private _tmpbuttonState: ExportStatus | null = null;
  private buttonStateSubject: Subject<ExportStatus | null> = new Subject();

  private panelStateSubject: Subject<boolean> = new Subject();

  private _tmpEntryList: IDMEntry[] = [];
  private entryListSubject: Subject<IDMEntry[]> = new Subject();

  constructor(
    private accountService: AccountService,
    private contextService: ContextService,
    private http: HttpClient,
    private snackBar: SnackbarService
  ) {
    // Start monitoring download states (polling)
    this.subscriptions.add(
      this.fetchDownloads$
        .pipe(
          switchMap(() => timer(100, POLLING_FREQUENCY * 1000)),
          mergeMap(() => this.getDownloadsStates()),
          retry(),
          share()
        )
        .subscribe(entries => this.manageDmEntries(entries))
    );

    document.addEventListener('visibilitychange', () => {
      // Focus back signet : call again DM
      if (!document.hidden) {
        this.getDownloadsStates().subscribe(entries => this.manageDmEntries(entries));
      }
    });

    this.fetchDownloads$.next();
  }

  /**
   * Displays the export status in a notification popup
   *
   * For each polled event, we have to compare previous export status with current one
   * to determine which type of notification to show
   */
  public manageToast({ id, assetId, exportStatus }: IDMEntry): void {
    const { DONE, ERROR, IN_PROGRESS, QUEUED } = ExportStatus;
    const previousEntry = this._tmpEntryList.find(tmpEntry => tmpEntry.id === id);
    const previousStatus = previousEntry?.exportStatus;
    const previousAsset = previousEntry?.assetId;

    // Status for this entry were already notified (prevents status spamming)
    // Note: For a given entry (same doc), IN_PROGRESS notification must be shown
    // even if previous export is still in progress (the doc could be modified in the meantime => different assetId)
    if (assetId === previousAsset && exportStatus === previousStatus) {
      return;
    }

    const isExportSuccessfull = exportStatus === DONE;
    const isExportFailure = exportStatus === ERROR;
    const isExportQueuedOrInProgress = exportStatus === IN_PROGRESS || exportStatus === QUEUED;

    if (isExportSuccessfull) {
      this.snackBar.open('global.dm.toast.done', SNACKBAR_TYPE.success);
    } else if (isExportFailure) {
      this.snackBar.open('global.dm.toast.error', SNACKBAR_TYPE.danger);
    } else if (isExportQueuedOrInProgress) {
      this.snackBar.open('global.dm.toast.new', SNACKBAR_TYPE.info);
    }
  }

  public forceCall(): void {
    this.getDownloadsStates().subscribe((entries: IDMEntry[]) => {
      this.manageDmEntries(entries);
      this.isFirstCallDone = true;
    });
  }

  public getDownloadsStates(): Observable<IDMEntry[]> {
    const params = new HttpParams().set('spinner', 'none').set('skipError', 'true');

    const documentId = this.accountService.documentId;
    if (!documentId) {
      return EMPTY;
    }
    return this.http.get<IDMEntry[]>(`${DOCUMENTS_API_URL}/${documentId}/download-manager`, { params });
  }

  public getButtonState(): Observable<ExportStatus | null> {
    return this.buttonStateSubject.asObservable();
  }

  public getActualButtonState(): ExportStatus | null {
    return this._tmpbuttonState;
  }

  public togglePanel(value: boolean): void {
    this.panelStateSubject.next(value);
  }

  public getPanelState(): Observable<boolean> {
    return this.panelStateSubject.asObservable();
  }

  public getEntryList(): Observable<IDMEntry[]> {
    return this.entryListSubject.asObservable();
  }

  public getActualEntryList(): IDMEntry[] {
    return this._tmpEntryList;
  }

  public downloadFileURL(id: number): string {
    return `${DOCUMENTS_API_URL}/${this.accountService.documentId}/download-manager/download/${id}`;
  }

  public downloadErrorReportURL(id: number): string {
    return `${DOCUMENTS_API_URL}/${this.accountService.documentId}/download-manager/report/${id}`;
  }

  public downloadReportURL(reportId: number): string {
    const currentProjectId = this.contextService.currentProjectId;
    return `${PROJECTS_API_URL}/${currentProjectId}/dynamic-data/xbrl/amfReportContent/${reportId}`;
  }

  public getReportStatus(reportId: number): Observable<AMFValidateReportResult> {
    const currentProjectId = this.contextService.currentProjectId;
    return this.http.get<AMFValidateReportResult>(
      `${PROJECTS_API_URL}/${currentProjectId}/dynamic-data/xbrl/amfReportStatus/${reportId}`,
      {}
    );
  }

  public saveExportedDocument(response: HttpResponse<Blob>, contentType: string): void {
    if (!response.ok || !response.body) {
      return console.error(response);
    }
    const contentDisposition = response.headers.get('content-disposition');
    const filename = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
    const file = new Blob([response.body], { type: contentType });
    saveAs(file, filename);
  }

  public deleteById(id: number): Observable<void> {
    const params = new HttpParams().set('spinner', 'none');
    return this.http.delete<void>(`${DOCUMENTS_API_URL}/${this.accountService.documentId}/download-manager/delete/${id}`, { params });
  }

  public deleteAll(): Observable<void> {
    const params = new HttpParams().set('spinner', 'none');
    return this.http.delete<void>(`${DOCUMENTS_API_URL}/${this.accountService.documentId}/download-manager/delete`, { params });
  }

  public cancelExport(id: number): Observable<void> {
    const params = new HttpParams().set('spinner', 'none');
    return this.http.put<void>(`${DOCUMENTS_API_URL}/${this.accountService.documentId}/download-manager/cancel/${id}`, { params });
  }

  public recallExport(id: number): Observable<void> {
    const params = new HttpParams().set('spinner', 'none');
    return this.http.put<void>(`${DOCUMENTS_API_URL}/${this.accountService.documentId}/download-manager/recall/${id}`, { params });
  }

  private manageDmEntries(entries: IDMEntry[]): void {
    if (!entries) {
      return;
    }
    const { DONE, ERROR, IN_PROGRESS, QUEUED, WARNING, DONE_WITH_WARNINGS } = ExportStatus;

    let hasEntryWithWarning = false;
    if (this.accountService.hasAnyAuthority(Authority.SUPER_ADMIN)) {
      // Show warning state only if super admin
      hasEntryWithWarning = entries.some(({ exportStatus }) => exportStatus === DONE_WITH_WARNINGS);
    } else {
      entries.forEach((entry: IDMEntry, index: number) => {
        if (entry.exportStatus === WARNING) {
          entries[index].exportStatus = IN_PROGRESS;
        } else if (
          entry.exportStatus === DONE_WITH_WARNINGS &&
          [ExportType.PDF, ExportType.PDF_HD, ExportType.PDF_LD].includes(entry.exportType)
        ) {
          entries[index].exportStatus = DONE;
        }
      });
    }
    const hasEntryWithErrors = entries.some(({ exportStatus }) => exportStatus === ERROR);
    const hasEntryInProgressOrQueued = entries.some(
      ({ exportStatus }) => exportStatus === IN_PROGRESS || exportStatus === QUEUED || exportStatus === WARNING
    );
    const hasEntryDone = entries.some(({ exportStatus }) => exportStatus === DONE);

    if (this.isFirstCallDone) {
      entries.forEach(entry => this.manageToast(entry));
    }

    // Save entries
    this._tmpEntryList = entries;
    this.entryListSubject.next(entries);

    // Calculate state of the toolbar button
    if (hasEntryWithErrors) {
      this._tmpbuttonState = ERROR;
      this.buttonStateSubject.next(ERROR);
    } else if (hasEntryWithWarning) {
      this._tmpbuttonState = WARNING;
      this.buttonStateSubject.next(WARNING);
    } else if (hasEntryInProgressOrQueued) {
      this._tmpbuttonState = IN_PROGRESS;
      this.buttonStateSubject.next(IN_PROGRESS);
    } else if (hasEntryDone) {
      this._tmpbuttonState = DONE;
      this.buttonStateSubject.next(DONE);
    } else {
      this._tmpbuttonState = null;
      this.buttonStateSubject.next(null);
    }
  }

  public downloadPDFReportURL(downloadEntryId: number): string {
    const documentId = this.accountService.documentId;
    return `${DOCUMENTS_API_URL}/${documentId}/export/report/${downloadEntryId}`;
  }

  public getPDFReport(downloadEntryId: number): Observable<PDFReportResult[]> {
    return this.http.get<PDFReportResult[]>(this.downloadPDFReportURL(downloadEntryId));
  }
}
