import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'app/core/auth/account.service';
import { ArevioService } from 'app/core/service/arevio.service';
import { ProjectService } from 'app/core/service/project.service';
import { PluginPanelService } from 'app/html-editor/plugins/plugin-panel.service';
import { PluginComponent } from 'app/html-editor/plugins/plugin.component';
import { PluginsCommand } from 'app/html-editor/plugins/plugins-commands';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_EXECUTE } from 'app/pubsub.topics';
import { CKEditorDynamicDataModels } from 'app/shared/enum/ckeditor-model.enum';

import { LOCALE_ENUM } from 'app/shared/enum/locale.enum';
import { SepXbrlMultiPart } from 'app/shared/enum/sep-xbrl-multi-part.enum';
import { TaggingStep } from 'app/shared/enum/tagging-step.enum';

import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { IFact, IFactUtils } from 'app/shared/model/fact.model';
import { IMonetaryUnit } from 'app/shared/model/monetary-unit.model';
import { SpreadsheetUtils } from 'app/shared/util/spreadsheet-utils';

import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

// this one does not want to work with import even with webpack
// eslint-disable-next-line
// todo to remove if globalize is not wanted, it is used to transfo litteral number to number (cent vingt to 120)
/*
const Globalize = require('globalize');

import SUPPLEMENTAL_1 from 'cldr-data/supplemental/numberingSystems.json';
import SUPPLEMENTAL_2 from 'cldr-data/supplemental/likelySubtags.json';
Globalize.load(SUPPLEMENTAL_2, SUPPLEMENTAL_1);

import EN from 'cldr-data/main/en/numbers.json';
import FR from 'cldr-data/main/fr/numbers.json';
import ES from 'cldr-data/main/es/numbers.json';
import AR from 'cldr-data/main/ar/numbers.json';
Globalize.load(EN, FR, ES, AR);
*/

const isFactReady = (fact: IFact): boolean => {
  const periodIsOK = !!fact?.context?.period?.id;
  const dimensionIsOK =
    !fact?.context?.dimensions?.length ||
    fact?.context?.dimensions?.filter(dim => dim?.dimAxis?.qname).every(dim => dim?.dimMember?.qname != null);
  const unitIsOK =
    fact?.concept?.type && [XBRLType.MONETARY, XBRLType.PER_SHARE, XBRLType.PER_SHARE_2021].includes(fact?.concept?.type)
      ? !!fact?.oimUnit
      : true;
  return periodIsOK && dimensionIsOK && unitIsOK;
};

enum DynamicDataTaggingOption {
  REPLACE,
  ADD,
}

@Component({
  selector: 'jhi-dynamic-data-tagging-plugin',
  templateUrl: './dynamic-data-tagging-plugin.component.html',
  styleUrls: ['./dynamic-data-tagging-plugin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDataTaggingPluginComponent extends PluginComponent implements OnInit, OnDestroy {
  public readonly XBRLType = XBRLType;

  public selectedText: string;
  public scale = 0;
  public isPicking = false;
  public isMultiIndexed = false;
  public addOptionMandatory = false;
  public factForPicking: IFact;
  public isActionsButtonsDisplayed = true;
  public format = false;
  public projectUnits: IMonetaryUnit[];
  public valueToDisplay: string;
  public DynamicDataTaggingOption = DynamicDataTaggingOption;
  public formPickingOption: UntypedFormGroup;
  private defaultGroupingSeparator: string;
  private defaultDecimalsSeparator: string;
  private locale: LOCALE_ENUM;

  public get multiplier(): string {
    return Number.isInteger(this.scale) ? Math.pow(10, this.scale).toLocaleString() : '';
  }

  public displayWarning = false;

  public fact: IFact = {
    context: {
      dimensions: [],
      period: null,
      entityScheme: '',
      entityIdentifier: null,
    },
  };

  public readonly monetaryForm: UntypedFormGroup;
  public readonly dateForm: UntypedFormGroup;
  private readonly fact$: Subject<IFact> = new BehaviorSubject<IFact>({});
  readonly isFactReady$: Observable<boolean> = this.fact$.pipe(
    map((fact: IFact) => isFactReady(fact)),
    distinctUntilChanged()
  );
  readonly templateType$: Observable<string> = this.fact$.pipe(
    map((fact: IFact) => {
      switch (fact?.concept?.type) {
        case XBRLType.MONETARY:
        case XBRLType.PER_SHARE:
        case XBRLType.PER_SHARE_2021:
        case XBRLType.SHARES:
        case XBRLType.DECIMAL:
          return 'NUMBER';
        case XBRLType.DATE:
          return 'DATE';
        default:
          return '';
      }
    }),
    distinctUntilChanged()
  );
  private readonly valueChange$: Subject<void> = new Subject<void>();
  private readonly isValueReady$: Observable<boolean> = this.valueChange$.pipe(
    map(() => {
      switch (this.fact?.concept?.type) {
        case XBRLType.MONETARY:
        case XBRLType.PER_SHARE:
        case XBRLType.PER_SHARE_2021:
        case XBRLType.SHARES:
        case XBRLType.DECIMAL:
          return this.monetaryForm.valid;
        case XBRLType.DATE:
          return this.dateForm.valid;
        default:
          return true;
      }
    }),
    distinctUntilChanged()
  );
  readonly isReadyToSave$: Observable<boolean> = combineLatest([this.isFactReady$, this.isValueReady$]).pipe(
    map(([factReady, valueReady]: [boolean, boolean]) => {
      return factReady && valueReady;
    }),
    distinctUntilChanged()
  );

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private arevioService: ArevioService,
    private projectService: ProjectService,
    private accountService: AccountService,
    private panelService: PluginPanelService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super(panelService);

    // Monetary form.
    this.monetaryForm = this.fb.group({
      factValue: new UntypedFormControl(null, [Validators.required]),
      scale: new UntypedFormControl(0, [Validators.required]),
    });
    this.subscriptions.add(this.monetaryForm.statusChanges.subscribe(() => this.valueChange$.next()));
    this.subscriptions.add(
      this.monetaryForm.controls.scale.valueChanges.subscribe((value: string) => {
        this.scale = parseInt(value, 10);
      })
    );

    // Date form.
    this.dateForm = this.fb.group({
      factValue: new UntypedFormControl(null, [Validators.required]),
    });
    this.subscriptions.add(this.dateForm.statusChanges.subscribe(() => this.valueChange$.next()));
  }

  ngOnInit(): void {
    // Get selected text.
    this.selectedText = this.getHtmlEditorSelectedText();
    this.locale = this.accountService.getLocale();
    this.formPickingOption = new UntypedFormGroup({
      saveOption: new UntypedFormControl(DynamicDataTaggingOption.REPLACE),
    });

    // - monetary scale value.
    this.projectService.getProjectInformation().subscribe(({ project }) => {
      this.monetaryForm.controls.scale.setValue(project.scale);
      this.defaultDecimalsSeparator = project.decimalsSeparator;
      this.defaultGroupingSeparator = project.groupingSeparator;
      // Set forms initial values.
      this.initFormsFactValues(this.selectedText);
    });
    this.projectService.getProjectMonetaryUnits().subscribe(units => (this.projectUnits = units));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getHtmlEditorSelectedText(): string {
    const selection = this.editor.model.document.selection;
    const range = selection.getFirstRange();
    let selectedText = '';
    for (const item of range?.getItems()) {
      if (item.name && CKEditorDynamicDataModels.includes(item.name)) {
        this.displayWarning = true;
        const factValue = item.getAttribute('custom-value');
        selectedText += factValue ?? item.getAttribute('value');
      } else if (item.name === 'softBreak') {
        selectedText += '\n';
      } else {
        selectedText += item.data || ''; // returns the selected text
      }
    }
    return selectedText;
  }

  private initFormsFactValues(value: string): void {
    // dateForm fact value.
    const formattedDateToISO = SpreadsheetUtils.formatInputDateToISO8601(value, this.locale);
    const isDate: moment.Moment = moment(formattedDateToISO, 'YYYY-MM-DD');
    this.dateForm.controls.factValue.setValue(isDate.isValid() ? formattedDateToISO : null);

    // monetaryForm fact value.
    let parsedValue = Number.NaN;
    // todo to fix the Globalize().numberParser() is not a function
    /*
    let i = 0;
    const lng = ['en', 'fr', 'es', 'ar'];
    while (isNaN(parsedValue) && i < lng.length) {
      parsedValue = Globalize(lng[i++]).numberParser()(value);
    }
    */
    if (isNaN(parsedValue)) {
      parsedValue = parseFloat(
        value.replace(/\s/g, '').replace(new RegExp(this.defaultGroupingSeparator, 'g'), '').replace(this.defaultDecimalsSeparator, '.')
      );
    }
    this.monetaryForm.controls.factValue.setValue(isNaN(parsedValue) ? '' : parsedValue);

    // string fact value.
    this.valueToDisplay = value;

    // .
    this.valueChange$.next();
  }

  /**
   * set "saveOption" value to "value"
   * enable/disable save option field
   */
  private _updateSaveOption(value: DynamicDataTaggingOption, enabled: boolean) {
    this.formPickingOption.controls.saveOption.setValue(value);
    if (enabled) {
      this.formPickingOption.controls.saveOption.enable();
    } else {
      this.formPickingOption.controls.saveOption.disable();
    }
    this.addOptionMandatory = value === DynamicDataTaggingOption.ADD && !enabled;
    this.cdr.markForCheck();
  }

  setDefaultValues(): void {
    this.isPicking = false;
    this.isMultiIndexed = false;
    this.factForPicking = {};
    this.initFormsFactValues(this.selectedText);
    this._updateSaveOption(DynamicDataTaggingOption.REPLACE, true);
    this.cdr.markForCheck();
  }

  /**
   * disable options and check replace for string fact multi indexed
   * else select REPLACE option and enable options
   */
  private _updateFactOption() {
    if (this.factForPicking?.concept?.type === XBRLType.STRING && this.factForPicking.factXbrlId) {
      this._updateSaveOption(DynamicDataTaggingOption.REPLACE, false);
      this.arevioService.isMultiIndexedFact(this.factForPicking.factXbrlId).subscribe(({ isMultiIndex }) => {
        this.isMultiIndexed = isMultiIndex;
        if (!isMultiIndex) {
          this._updateSaveOption(DynamicDataTaggingOption.REPLACE, true);
        }
        this.cdr.markForCheck();
      });
    } else {
      this._updateSaveOption(DynamicDataTaggingOption.REPLACE, true);
    }
  }

  /**
   * update fact when selection change
   * (factUpdated event from jhi-xbrl-data-tagger)
   */
  updateFact(fact: IFact): void {
    this.fact = fact;
    this.fact$.next(this.fact);
    if (isFactReady(this.fact)) {
      this.arevioService.getFactById(this.arevioService.getFactXbrlId(this.fact)).subscribe(
        (factForPicking: IFact) => {
          if (factForPicking?.id) {
            this.isPicking = true;
            this.isMultiIndexed = false;
            this.factForPicking = factForPicking;
            this.initFormsFactValues(factForPicking.factValue ?? this.selectedText);
            if (IFactUtils.isStringMultiZone(this.factForPicking)) {
              // only ADD option available for fact multi zone
              this._updateSaveOption(DynamicDataTaggingOption.ADD, false);
            } else {
              this._updateFactOption();
            }
          } else {
            this.setDefaultValues();
          }
        },
        () => {
          this.setDefaultValues();
        }
      );
    }
  }

  updateTaggingStep(step: TaggingStep): void {
    this.panelService.toggleHeaderDisplay(step !== TaggingStep.CHOICE);
    this.isActionsButtonsDisplayed = step !== TaggingStep.CONCEPT;
  }

  remove(): void {
    this.onClickClosePanel();
  }

  private _tagInPage(fact: IFact) {
    pubsub.fire(
      EDITOR_PLUGIN_EXECUTE,
      {
        command: PluginsCommand.INSERT_XBRL,
        params: { data: fact, format: {}, projectUnits: this.projectUnits },
      },
      this.getEditorTopicContext()
    );
    this.onClickClosePanel();
  }

  private _saveNewFact(): void {
    if (!this.fact.concept?.type || !this.fact.context?.period) {
      return;
    }
    let factValue: string = this.selectedText;
    switch (this.fact.concept?.type) {
      case XBRLType.PER_SHARE:
      case XBRLType.PER_SHARE_2021:
      case XBRLType.SHARES:
      case XBRLType.MONETARY:
      case XBRLType.DECIMAL: {
        const value: number = this.monetaryForm.controls.factValue.value;
        const scale: number = this.monetaryForm.controls.scale.value;
        factValue = `${value * Math.pow(10, scale)}`;
        break;
      }
      case XBRLType.DATE: {
        factValue = moment(this.dateForm.controls.factValue.value).format('YYYY-MM-DD');
        break;
      }
      case XBRLType.PERCENT:
      case XBRLType.PERCENT_2021:
      default:
        break;
    }

    const dimensions = this.fact.context?.dimensions
      .filter(dim => dim?.dimAxis?.qname || dim?.dimMember?.qname) // purge empty dim
      .map(dim => ({
        axisQname: dim.dimAxis.qname,
        memberQnames: dim.dimMember.qname,
      }));

    const { qname, type } = this.fact.concept;
    const { oimUnit } = this.fact;
    const { startDate, endDate, periodType } = this.fact.context.period;
    this.arevioService
      .tagNewFact(
        {
          qname,
          dimensions,
          factValue,
          oimUnit,
          startDate,
          endDate,
          periodType,
        },
        type
      )
      .subscribe((fact: IFact) => {
        switch (fact.concept?.type) {
          case XBRLType.DATE:
          case XBRLType.PER_SHARE:
          case XBRLType.PER_SHARE_2021:
          case XBRLType.SHARES:
          case XBRLType.MONETARY:
          case XBRLType.DECIMAL:
            this.factForPicking = fact;
            this.format = true;
            this.cdr.markForCheck();
            break;
          default:
            this._tagInPage(fact);
            break;
        }
      });
  }

  /**
   * Override the selection with the current value.
   */
  private _saveWithReplaceOption() {
    switch (this.factForPicking.concept?.type) {
      case XBRLType.DATE:
      case XBRLType.PER_SHARE:
      case XBRLType.PER_SHARE_2021:
      case XBRLType.SHARES:
      case XBRLType.MONETARY:
      case XBRLType.DECIMAL:
        this.format = true;
        this.cdr.markForCheck();
        break;
      default:
        // XBRLType.PERCENT,
        // XBRLType.PERCENT_2021:
        // XBRLType.STRING,
        // XBRLType.TIME,
        // XBRLType.DATE_TIME,
        // XBRLType.TEXT_BLOCK,
        // XBRLType.STRING
        this._tagInPage(this.factForPicking);
        this.onClickClosePanel();
        break;
    }
  }

  /**
   * Append the current selection to this fact.
   */
  private _saveWithAddOption(): void {
    if (!this.factForPicking.id || !this.factForPicking.factValue) {
      return;
    }
    this.factForPicking.factValue += SepXbrlMultiPart.DATA + this.selectedText;
    this.arevioService
      .setFactValue({
        factId: this.factForPicking.id,
        factValue: this.factForPicking.factValue,
      })
      .subscribe(() => {
        // set factIndex value for insert the good attribute index
        this.factForPicking.factIndex = IFactUtils.nbZoneFactString(this.factForPicking) - 1;
        this._tagInPage(this.factForPicking);
      });
  }

  save(): void {
    if (this.isPicking) {
      if (this.formPickingOption.controls.saveOption.value === DynamicDataTaggingOption.REPLACE) {
        this._saveWithReplaceOption();
      } else {
        this._saveWithAddOption();
      }
    } else {
      this._saveNewFact();
    }
  }
}
